import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { SPFType } from "@maeva/api/search";
import { createAction, createSlice } from "@reduxjs/toolkit";

import { analytics } from "../../firebase";
import { history } from "../../utils/history";
import { INITIAL_SEARCH_STATE, KEY } from "./constants";
import { makeSearchState } from "./utils";

export const search = createAction(`maeva/${KEY}/search`, (searchState) => ({
  payload: { ...searchState },
}));
export const toggleOpenResultsInNewTab = createAction(
  `maeva/${KEY}/toggleOpenResultsInNewTab`
);

const persistConfig = {
  key: KEY,
  storage: storage,
  whitelist: ["openResultsInNewTab"],
};

const slice = createSlice({
  name: KEY,
  initialState: {
    openResultsInNewTab: true,
    searchState: INITIAL_SEARCH_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(search, (state, action) => {
      // Searches are made by pushing new state to history, we do that here.
      // Note that passed state can be partial, so we call `makeSearchState`.
      const newState = {
        ...history.location.state,
        ...action.payload,
      };

      if (newState.spfType === SPFType.All) {
        newState.spfType = null;
      }

      state.searchState = makeSearchState(newState);
    });

    builder.addCase(toggleOpenResultsInNewTab, (state, action) => {
      state.openResultsInNewTab = !state.openResultsInNewTab;
      analytics.logEvent("toggle_results_in_new_tab", {
        on: state.openResultsInNewTab,
      });
    });
  },
});

export const searchReducer = persistReducer(persistConfig, slice.reducer);
