import React from "react";
import { useDispatch } from "react-redux";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import {
  finishSignIn,
  reaffirmSignInEmail,
  useSignInState,
  useSignInValidationState,
} from "../../../store/signIn";
import { theme } from "../../Theme";
import { EmailInput } from "../../atoms";
import { SnackbarSeverity, showSnackbar } from "../../store/snackbar";

const EMAIL_FIELD_ID = "verify-sign-in-user-email";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    overflow: "hidden",
    padding: theme.spacing(2),
    minWidth: theme.spacing(32),
    minHeight: theme.spacing(12),
  },
  pending: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
  },
}));

function SigningInScreen() {
  const classes = useStyles();
  return (
    <>
      <DialogTitle id="validate-dialog-title">Signing in</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <CircularProgress size={theme.spacing(10)} />
      </DialogContent>
    </>
  );
}

function EmailScreen() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { pending } = useSignInValidationState();
  const [email, setEmail] = React.useState("");

  const close = () => {
    setEmail("");
    dispatch(finishSignIn());
  };

  const handleNext = () => {
    if (!email || !document.getElementById(EMAIL_FIELD_ID).validity.valid) {
      dispatch(
        showSnackbar("Please enter a valid email", SnackbarSeverity.INFO)
      );
    } else if (!pending) {
      dispatch(reaffirmSignInEmail(email));
    }
  };

  return (
    <>
      <DialogTitle id="validate-dialog-title">Verify email</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText id="validate-dialog-description">
          For security, please verify the email address you signed up with
        </DialogContentText>
        <EmailInput
          value={email}
          setValue={setEmail}
          onPressEnter={handleNext}
          autoFocus
          id={EMAIL_FIELD_ID}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="secondary" tabIndex="4">
          Cancel
        </Button>
        <Button onClick={handleNext} color="primary">
          {pending && (
            <CircularProgress
              className={classes.pending}
              size={theme.spacing(2)}
            />
          )}
          Next
        </Button>
      </DialogActions>
    </>
  );
}

export default function ValidateSignInDialog() {
  const { email } = useSignInState();
  const { active } = useSignInValidationState();

  return (
    <Dialog
      open={active}
      disableEscapeKeyDown
      aria-labelledby="validate-dialog-title"
      aria-describedby="validate-dialog-description"
      style={{
        textAlign: "center",
      }}
    >
      {active && !email ? <EmailScreen /> : <SigningInScreen />}
    </Dialog>
  );
}
