import firebase from "firebase/app";

/**
 * Fetches a vendor from Firestore by id
 * @param {String} id The vendor id
 * @returns The vendor data
 */
export async function fetchVendorById(id) {
  try {
    const firestore = firebase.firestore();
    const vendors = firestore.collection("vendors");
    const doc = await vendors.doc(id).get();

    if (doc.exists) {
      return doc.data();
    } else {
      console.error(`Found no vendor with id: "${id}"`);
    }
  } catch (error) {
    console.error(`Failed to fetch Firestore vendor: ${error}`);
  }

  return null;
}

/**
 * Fetches all vendors from Firestore
 * @returns Array of vendors
 */
export async function fetchVendors() {
  try {
    const firestore = firebase.firestore();
    const vendors = firestore.collection("vendors");
    const docs = await vendors.get();

    if (docs && docs.size > 0) {
      return docs.docs.map((doc) => doc.data());
    } else {
      console.error("Found no vendors");
    }
  } catch (error) {
    console.error(`Failed to fetch Firestore vendors: ${error}`);
  }

  return [];
}
