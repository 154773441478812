import React from "react";

import { IconButton, Paper, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AddCommentIcon from "@mui/icons-material/AddComment";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    bottom: 10,
    right: 10,
    borderRadius: "100%",
    zIndex: 10000,
  },
});

export default function Feedback() {
  const classes = useStyles();
  return (
    <Paper className={classes.root} elevation={3}>
      <Tooltip title="Feedback" placement="left" arrow>
        <a
          href="https://docs.google.com/forms/d/1wTVUxIN1birSvbPw653RQ0huhnhW6rzwNoSsctAKq3k"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton aria-label="Give feedback" size="large">
            <AddCommentIcon />
          </IconButton>
        </a>
      </Tooltip>
    </Paper>
  );
}
