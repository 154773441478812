import { ofType } from "redux-observable";
import { catchError, ignoreElements, mergeMap, of, switchMap } from "rxjs";

import { SnackbarSeverity, showSnackbar } from "../../../lib/store/snackbar";
import { catchSignOutError, signOut } from "../slice";

export default (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(signOut),
    mergeMap((action) =>
      of(action).pipe(
        switchMap(() => firebase.auth().signOut()),
        ignoreElements(),
        catchError((error) => {
          console.error(`Error signing out ${error.code}:`, error.message);
          return [
            showSnackbar(
              "Could not sign out, please try again",
              SnackbarSeverity.ERROR
            ),
            catchSignOutError(),
          ];
        })
      )
    )
  );
