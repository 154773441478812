import { MAX_SPF } from "../store/search";

const priceFormatter = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

/**
 * @param {object} variant
 * @returns An object to send to Google Analytics describing a product
 */
export function getAnalyticsParams(variant) {
  return {
    id: variant.id,
    name: variant.name,
    brand: variant.brand_id,
    popularity: variant.popularity,
    price: variant.price,
    rating: variant.rating,
    rating_count: variant.rating_count,
    spf: variant.spf,
    size: variant.size,
    pa_strength: variant.pa_strength,
  };
}

export function makeProductName({ product, includeBrand = true }) {
  if (!product) return null;
  const brandToStr = (brand) => (includeBrand && brand ? brand : "");
  const spfToStr = (spf) => (spf ? `SPF ${spf}` : "");
  const sizeToStr = (size) => (size && size.split(", ").length < 3 ? size : "");
  return `${brandToStr(product.brand)} ${product.name ?? ""} ${spfToStr(
    product.spf
  )} ${product.pa_strength ?? ""} ${sizeToStr(product.size)}`.trim();
}

export function spfRangeToStr(spfRange) {
  const spfToStr = (spf) => (spf === MAX_SPF ? `${MAX_SPF}+` : spf);
  if (spfRange[0] === spfRange[1]) {
    return `SPF ${spfToStr(spfRange[0])}`;
  } else {
    return `SPF ${spfRange[0]}-${spfToStr(spfRange[1])}`;
  }
}

export function formatPrice(price) {
  // Take a float and format it as an en-GB price, rounded if 0 pence
  let formatted = priceFormatter.format(price);

  if (formatted.endsWith(".00")) {
    formatted = formatted.substring(0, formatted.indexOf("."));
  }

  return formatted;
}
