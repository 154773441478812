import classNames from "classnames";
import React from "react";

import { CircularProgress, Button as MUIButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { primaryFontFamily, theme } from "../Theme";

const ColorTypes = Object.freeze({
  primary: "primary",
  secondary: "secondary",
});

const VariantTypes = Object.freeze({
  contained: "contained",
  outlined: "outlined",
  text: "text",
});

const SizeTypes = Object.freeze({
  small: "small",
  medium: "medium",
  large: "large",
});

const getThemeColor = (colorType) =>
  colorType === ColorTypes.primary
    ? theme.palette.primary.main
    : theme.palette.secondary.main;

const getColor = (props) =>
  props.variant === VariantTypes.contained
    ? theme.palette.white.main
    : getThemeColor(props.color);

const useStyles = makeStyles({
  root: {
    color: getColor,
    textTransform: "none",
    boxShadow: "none",
    fontFamily: primaryFontFamily,
    borderRadius: 10,
    "&:hover": {
      borderColor: (props) =>
        props.variant === VariantTypes.outlined ? "inherit" : null,
      backgroundColor: (props) =>
        props.variant === VariantTypes.outlined
          ? "rgba(255, 255, 255, 0.2)"
          : null,
    },
    "& .MuiButton-label": {
      marginTop: (props) => (props.size === SizeTypes.small ? 0 : -1),
    },
    "& a, a:hover, a:not(:hover)": {
      textDecoration: "none",
      color: "inherit",
    },
  },
  pending: {
    color: getColor,
    marginRight: theme.spacing(1),
  },
});

export default function Button({ className, pending, children, ...props }) {
  const classes = useStyles(props);
  return (
    <MUIButton className={classNames(classes.root, className)} {...props}>
      {pending && (
        <CircularProgress
          className={classNames("maeva-btn-pending", classes.pending)}
          size={
            props.size === SizeTypes.small ? theme.spacing(2) : theme.spacing(3)
          }
        />
      )}
      {children}
    </MUIButton>
  );
}
