import { useSelector } from "react-redux";
import { combineReducers } from "redux";

import { snackbarEpics, snackbarReducer } from "./snackbar";

export const maevaUIRootEpic = [...snackbarEpics];
export const maevaUIRootReducer = combineReducers({
  snackbar: snackbarReducer,
});

export function useMaevaUIState() {
  return useSelector((state) => state.maevaUI);
}
