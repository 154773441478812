import React from "react";

import { theme } from "../../Theme";
import svg from "./UKFlagIcon.svg";

export default function UKFlagIcon({ fontSize = "medium" }) {
  return (
    <img
      className="MuiSvgIcon-root"
      src={svg}
      alt="UK Flag"
      style={{
        width: "auto",
        height: theme.spacing(fontSize === "medium" ? 3 : 2),
      }}
    />
  );
}
