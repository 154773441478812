import checkUserExists from "./epics/checkUserExists";
import createUser from "./epics/createUser";
import sendSignInEmail from "./epics/sendSignInEmail";
import signIn from "./epics/signIn";
import validateSignIn from "./epics/validateSignIn";

export const signInEpics = [
  checkUserExists,
  sendSignInEmail,
  createUser,
  signIn,
  validateSignIn,
];
export { SignInScreens, SignInModes } from "./constants";
export {
  useSignInLaunchState,
  useSignInValidationState,
  useSignInState,
} from "./hooks";
export {
  openSignIn,
  closeSignIn,
  checkUserExists,
  sendSignInEmail,
  resendSignInEmail,
  reauthenticate,
  catchLaunchSignInError,
  backToRequestEmail,
  askForName,
  signInLaunchDone,
  validateSignIn,
  startSignIn,
  reaffirmSignInEmail,
  clearSignInEmail,
  catchValidateSignInError,
  finishSignIn,
} from "./slice";
