import React from "react";
import { useDispatch } from "react-redux";

import EditIcon from "@mui/icons-material/Edit";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PersonIcon from "@mui/icons-material/Person";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { signOut } from "../../store/auth";
import { openProfile } from "../../store/profile";
import { ProfileDialog } from "./";

const StyledMuiListItemIcon = withStyles((theme) => ({
  root: {
    minWidth: theme.spacing(4),
  },
}))(ListItemIcon);

export default function AccountMenu({ size }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuId = "account-menu";
  const fontSize = size === "medium" ? "default" : size;

  const open = (event) => setAnchorEl(event.currentTarget);
  const close = () => setAnchorEl(null);

  const handleEditProfile = () => {
    close();
    dispatch(openProfile());
  };

  const handleSignOut = () => {
    close();
    dispatch(signOut());
  };

  return (
    <>
      <IconButton
        size={size}
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={open}
      >
        <PersonIcon fontSize={fontSize} />
      </IconButton>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={close}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleEditProfile}>
          <StyledMuiListItemIcon>
            <EditIcon />
          </StyledMuiListItemIcon>
          <ListItemText>Edit Profile</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleSignOut}>
          <StyledMuiListItemIcon>
            <ExitToAppIcon />
          </StyledMuiListItemIcon>
          <ListItemText>Sign out</ListItemText>
        </MenuItem>
      </Menu>
      <ProfileDialog />
    </>
  );
}
