import { createAction, createSlice } from "@reduxjs/toolkit";

import { SignInModes } from "../signIn";
import { KEY, ProfileScreens } from "./constants";

export const openProfile = createAction(`maeva/${KEY}/openProfile`);
export const closeProfile = createAction(`maeva/${KEY}/closeProfile`);
export const openUpdateNameScreen = createAction(
  `maeva/${KEY}/openUpdateNameScreen`
);
export const openUpdateEmailScreen = createAction(
  `maeva/${KEY}/openUpdateEmailScreen`
);
export const openConfirmDeletionScreen = createAction(
  `maeva/${KEY}/openConfirmDeletionScreen`
);
export const openVerifyEmailScreen = createAction(
  `maeva/${KEY}/openVerifyEmailScreen`
);
export const updateUsername = createAction(
  `maeva/${KEY}/updateUsername`,
  (name) => ({
    payload: { name },
  })
);
export const updateUsernameDone = createAction(
  `maeva/${KEY}/updateUsernameDone`
);
export const updateEmail = createAction(
  `maeva/${KEY}/updateEmail`,
  (email) => ({
    payload: { email },
  })
);
export const updateEmailDone = createAction(`maeva/${KEY}/updateEmailDone`);
export const deleteUser = createAction(`maeva/${KEY}/deleteUser`);
export const deleteUserDone = createAction(`maeva/${KEY}/deleteUserDone`);

const profileSlice = createSlice({
  name: KEY,
  initialState: {
    activeScreen: null, // The currently open profile screen
    pending: false, // Is an external call pending?
    reauthMode: null,
    error: false,
    errorMsg: null,
  },
  extraReducers: (builder) => {
    builder.addCase(openProfile, (state, action) => {
      state.activeScreen = ProfileScreens.Profile;
    });

    builder.addCase(closeProfile, (state, action) => {
      state.activeScreen = null;
    });

    builder.addCase(openUpdateNameScreen, (state, action) => {
      state.activeScreen = ProfileScreens.UpdateName;
    });

    builder.addCase(openUpdateEmailScreen, (state, action) => {
      state.activeScreen = ProfileScreens.UpdateEmail;
    });

    builder.addCase(openConfirmDeletionScreen, (state, action) => {
      state.activeScreen = ProfileScreens.ConfirmDeletion;
    });

    builder.addCase(openVerifyEmailScreen, (state, action) => {
      state.activeScreen = ProfileScreens.VerifyEmail;
    });

    builder.addCase(updateUsername, (state, action) => {
      state.pending = true;
    });

    builder.addCase(updateUsernameDone, (state, action) => {
      state.pending = false;
    });

    builder.addCase(updateEmail, (state, action) => {
      state.pending = true;
      state.reauthMode = SignInModes.reauthForEmail;
    });

    builder.addCase(updateEmailDone, (state, action) => {
      state.pending = false;
    });

    builder.addCase(deleteUser, (state, action) => {
      state.pending = true;
      state.reauthMode = SignInModes.reauthForDeletion;
    });

    builder.addCase(deleteUserDone, (state, action) => {
      state.pending = false;
    });
  },
});

export const profileReducer = profileSlice.reducer;
