import { maevaUIRootReducer } from "../lib/store";
import { authReducer } from "./auth/slice";
import { profileReducer } from "./profile/slice";
import { searchReducer } from "./search/slice";
import { signInReducer } from "./signIn/slice";

export default {
  maevaUI: maevaUIRootReducer,
  auth: authReducer,
  profile: profileReducer,
  search: searchReducer,
  signIn: signInReducer,
};
