import React from "react";

import { SPFType } from "@maeva/api/search";
import { ToggleButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ToggleButtonGroup } from "../atoms";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
}));

export default function SpfTypeSelector({ value, onChange }) {
  const classes = useStyles();
  const handleChange = (event, newValue) => onChange(newValue);
  return (
    <div className={classes.root}>
      <Typography
        id="spf-type-label"
        variant="subtitle1"
        color="secondary"
        gutterBottom
      >
        I'd prefer...
      </Typography>
      <ToggleButtonGroup
        value={value}
        onChange={handleChange}
        exclusive
        aria-labelledby="spf-type-label"
      >
        <ToggleButton value={SPFType.All}>All</ToggleButton>
        <ToggleButton value={SPFType.Chemical}>Chemical</ToggleButton>
        <ToggleButton value={SPFType.Mineral}>Mineral</ToggleButton>
        <ToggleButton value={SPFType.Hybrid}>Hybrid</ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
