import { ToggleButtonGroup as MuiToggleButtonGroup } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

const ToggleButtonGroup = withStyles((theme) => ({
  root: {
    display: "block",
  },
  grouped: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    textTransform: "initial",
    backgroundColor: theme.palette.light.main,
    color: theme.palette.secondary.main,
    "&.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.white.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.hightlight,
        color: theme.palette.white.main,
      },
    },
    '&[type="button"]:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
    '&[type="button"]:last-child': {
      marginRight: 0,
    },
    '&[type="button"]:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
      borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
      marginLeft: 0,
    },
    "&.MuiToggleButton-root.Mui-selected + .MuiToggleButton-root.Mui-selected":
      {
        borderLeft: `1px solid ${theme.palette.secondary.main}`,
      },
  },
}))(MuiToggleButtonGroup);

export default ToggleButtonGroup;
