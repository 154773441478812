import React from "react";

import { TextField } from "@mui/material";

export default function NameInput({
  value,
  setValue,
  onPressEnter,
  Component = TextField,
  ...props
}) {
  const handleChangeName = (event) => setValue(event.target.value);
  const handleOnPressEnter = (event) => {
    if (event.key === "Enter" && onPressEnter) {
      onPressEnter();
    }
  };

  if (Component === TextField) {
    props.label = "Name";
  }

  return (
    <Component
      type="text"
      inputProps={{
        "aria-label": "Name",
        name: "name",
      }}
      required
      value={value}
      onChange={handleChangeName}
      onKeyPress={handleOnPressEnter}
      {...props}
    />
  );
}
