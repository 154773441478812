import React from "react";

import MuiAlert from "@mui/material/Alert";

export default function Alert({ children, ...props }) {
  return (
    <MuiAlert elevation={6} variant="filled" {...props}>
      {children}
    </MuiAlert>
  );
}
