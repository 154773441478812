import React from "react";

import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { MAX_PRICE, MIN_PRICE } from "../../store/search";
import { formatPrice } from "../../utils/product";
import { Slider } from "../atoms";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTypography-subtitle1": {
      marginBottom: theme.spacing(-1),
    },
  },
  slider: {
    paddingTop: 0,
  },
}));

export default function PriceSlider({
  value = [MIN_PRICE, MAX_PRICE],
  onChange,
}) {
  const classes = useStyles();
  const [range, setRange] = React.useState([...value]);
  const handleChange = (_, newValue) => setRange(newValue);
  const handleChangeCommitted = (_, newValue) => onChange(newValue);
  const marks = [
    {
      value: MIN_PRICE,
      label: formatPrice(MIN_PRICE),
    },
    {
      value: MAX_PRICE,
      label: formatPrice(MAX_PRICE),
    },
  ];

  React.useEffect(() => {
    setRange([...value]);
  }, [value]);

  return (
    <div className={classes.sliderContainer}>
      <Typography id="price-slider" variant="subtitle1" color="secondary">
        Price
      </Typography>
      <Slider
        className={classes.slider}
        value={range}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        min={MIN_PRICE}
        max={MAX_PRICE}
        step={10}
        color="secondary"
        getAriaValueText={formatPrice}
        valueLabelDisplay="auto"
        aria-labelledby="price-slider"
        marks={marks}
      />
    </div>
  );
}
