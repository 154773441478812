import { ofType } from "redux-observable";
import { catchError, mergeMap, of, switchMap } from "rxjs";

import { SnackbarSeverity, showSnackbar } from "../../../lib/store/snackbar";
import { SignInModes, reauthenticate } from "../../signIn";
import { deleteUser, deleteUserDone } from "../slice";
import { closeProfile, openVerifyEmailScreen } from "..";

export default (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(deleteUser),
    mergeMap((action) =>
      of(action).pipe(
        switchMap(() => firebase.auth().currentUser.delete()),
        mergeMap(() => [
          deleteUserDone(),
          closeProfile(),
          showSnackbar(
            "Goodbye! We're sorry to see you go",
            SnackbarSeverity.INFO
          ),
        ]),
        catchError((error) => {
          if (error.code === "auth/requires-recent-login") {
            return [
              reauthenticate(SignInModes.reauthForDeletion),
              deleteUserDone(),
              openVerifyEmailScreen(),
            ];
          } else {
            console.error("Delete user error:", error);
            return [
              deleteUserDone(),
              showSnackbar(
                "Account deletion failed, please try again",
                SnackbarSeverity.ERROR
              ),
            ];
          }
        })
      )
    )
  );
