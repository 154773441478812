import { ofType } from "redux-observable";
import { map, switchMap, takeUntil, timer } from "rxjs";

import { hideSnackbar, showSnackbar } from "../slice";

export default (action$, state$) =>
  action$.pipe(
    ofType(showSnackbar),
    switchMap((action) =>
      timer(action.payload.duration).pipe(
        map(hideSnackbar),
        takeUntil(action$.pipe(ofType(showSnackbar)))
      )
    )
  );
