import React from "react";

import { Link as MuiLink } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import LogoImg from "../../assets/images/logo-white.png";
import { useIsSignedIn } from "../../store/auth";
import { Link } from "../atoms";
import { SignInButton } from "./auth";

const useStyles = makeStyles((theme) => ({
  footer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: theme.palette.dark.main,
    padding: theme.spacing(6),
  },
  logoImg: {
    alignSelf: "center",
    height: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  links: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    listStyle: "none",
    padding: 0,
    margin: theme.spacing(4),
    "& li": {
      alignSelf: "center",
      marginBottom: theme.spacing(2),
    },
    "& a": {
      color: theme.palette.white.main,
      fontSize: "1.5rem",
      textDecoration: "none",
      "&:not(:hover)": {
        textDecoration: "none",
      },
      "&:hover": {
        textDecoration: "underline",
      },
      "&:active": {
        color: theme.palette.primary.main,
      },
    },
  },
  signUpLink: {
    cursor: "pointer",
  },
}));

export default function Footer() {
  const classes = useStyles();
  const isSignedIn = useIsSignedIn();
  return (
    <div className={classes.footer}>
      <img className={classes.logoImg} src={LogoImg} alt="maeva logo" />
      <nav>
        <ul className={classes.links}>
          <li>
            <Link to="/">Home</Link>
          </li>
          {process.env.REACT_APP_FEATURE_BLOG === "true" && (
            <li>
              <Link to="/blog">Blog</Link>
            </li>
          )}
          <li>
            <Link to="/story">Our Story</Link>
          </li>
          {!isSignedIn && (
            <li>
              <SignInButton
                className={classes.signUpLink}
                text="Sign Up"
                Component={MuiLink}
              />
            </li>
          )}
          <li>
            <Link to="/terms">Terms</Link>
          </li>
          <li>
            <Link to="/privacy">Privacy</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}
