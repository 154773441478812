import React from "react";

import SearchIcon from "@mui/icons-material/Search";
import { Chip, IconButton, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import LogoImg from "../../assets/images/logo-black.png";
import { analytics } from "../../firebase";
import { useIsSignedIn } from "../../store/auth";
import { Link } from "../atoms";
import { AccountMenu, RegionMenu } from "./";
import { SignInButton } from "./auth";

// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const MOBILE_SIZE = "sm";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "content-box",
    height: theme.spacing(4),
    padding: theme.spacing(2),
    "& a": {
      lineHeight: theme.spacing(2),
    },
  },
  left: {
    display: "flex",
    alignItems: "center",
    "&>:not(:first-child)": {
      marginLeft: theme.spacing(0.5),
      [theme.breakpoints.up(MOBILE_SIZE)]: {
        marginLeft: theme.spacing(1),
      },
    },
  },
  right: {
    "&>:not(:last-child)": {
      marginRight: theme.spacing(0.5),
      [theme.breakpoints.up(MOBILE_SIZE)]: {
        marginRight: theme.spacing(1),
      },
    },
  },
  logoImg: {
    height: theme.spacing(2),
    verticalAlign: "bottom",
  },
  betaChip: {
    color: theme.palette.white.main,
  },
}));

export default function Header() {
  const classes = useStyles();
  const isSignedIn = useIsSignedIn();
  const gtMobile = useMediaQuery((theme) => theme.breakpoints.up(MOBILE_SIZE));
  const size = gtMobile ? "medium" : "small";
  const fontSize = gtMobile ? "medium" : "small";
  const handleSearchClick = () => analytics.logEvent("click_header_search");

  return (
    <div className={classes.header}>
      <div className={classes.left}>
        <Link to="/">
          <img className={classes.logoImg} src={LogoImg} alt="maeva logo" />
        </Link>
        <Chip
          className={classes.betaChip}
          label="beta"
          color="primary"
          size={size}
        />
        <RegionMenu size={size} />
      </div>
      <div className={classes.right}>
        <Link to="/search" onClick={handleSearchClick}>
          <IconButton size={size} aria-label="search">
            <SearchIcon fontSize={fontSize} />
          </IconButton>
        </Link>
        {/* <Link to="/cart">
          <IconButton size={size} aria-label="shopping cart">
            <ShoppingCartIcon fontSize={fontSize} />
          </IconButton>
        </Link> */}
        {isSignedIn ? (
          <AccountMenu size={size} />
        ) : (
          <SignInButton size={size} />
        )}
      </div>
    </div>
  );
}
