import { ofType } from "redux-observable";
import { catchError, from, mergeMap, of } from "rxjs";

import { SnackbarSeverity, showSnackbar } from "../../../lib/store/snackbar";
import { openProfile, updateUsername, updateUsernameDone } from "../slice";

export default (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(updateUsername),
    mergeMap((action) =>
      of(action).pipe(
        mergeMap(async (action) => {
          const user = firebase.auth().currentUser;
          const name = action.payload.name.trim();

          await user.updateProfile({ displayName: name });
          await firebase.firestore().collection("users").doc(user.uid).set(
            {
              name,
            },
            { merge: true }
          );

          return [
            updateUsernameDone(),
            openProfile(),
            showSnackbar("Name updated successfully", SnackbarSeverity.SUCCESS),
          ];
        }),
        mergeMap((promise) => from(promise)),
        catchError((error) => {
          console.error(`Error updating username: ${error}`);
          return [
            showSnackbar(
              "An unexpected error occurred, please try again",
              SnackbarSeverity.ERROR
            ),
            updateUsernameDone(),
          ];
        })
      )
    )
  );
