import deleteUser from "./epics/deleteUser";
import updateEmail from "./epics/updateEmail";
import updateUsername from "./epics/updateUsername";

export const profileEpics = [updateUsername, updateEmail, deleteUser];
export { ProfileScreens } from "./constants";
export { useProfileState } from "./hooks";
export {
  openProfile,
  closeProfile,
  openUpdateNameScreen,
  openUpdateEmailScreen,
  openConfirmDeletionScreen,
  openVerifyEmailScreen,
  updateUsername,
  updateUsernameDone,
  updateEmail,
  updateEmailDone,
  deleteUser,
  deleteUserDone,
  profileReducer,
} from "./slice";
