import React from "react";

export default function ExclusiveCheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="MuiSvgIcon-root"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M 21.082 5 L 21.082 18.997 C 21.082 20.096 20.178 20.996 19.073 20.996 L 5.009 20.996 C 3.904 20.996 3 20.096 3 18.997 L 3 5 C 3 3.9 3.904 3 5.009 3 L 19.073 3 C 20.178 3 21.082 3.9 21.082 5 Z M 13.988 11.964 L 18.979 6.998 L 17.021 5.046 L 12.03 10.013 L 7.037 5.046 L 5.09 6.985 L 5.09 7.011 L 10.069 11.964 L 5.09 16.921 L 5.09 16.945 L 7.038 18.883 L 12.03 13.916 L 17.022 18.883 L 18.98 16.933 L 13.988 11.964 Z"></path>
    </svg>
  );
}
