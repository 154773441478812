import { createEpicMiddleware } from "redux-observable";
import { persistStore } from "redux-persist";

import { configureStore } from "@reduxjs/toolkit";

import firebase from "../firebase";
import rootEpic from "./rootEpic";
import rootReducer from "./rootReducer";

export default function createStore() {
  const epicMiddleware = createEpicMiddleware({
    dependencies: {
      firebase,
    },
  });
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // redux-persist stores functions in `register` and `rehydrate`
          ignoredActionPaths: ["register", "rehydrate"],
        },
      }).concat(epicMiddleware),
  });

  epicMiddleware.run(rootEpic);

  return {
    store,
    persistor: persistStore(store),
  };
}
