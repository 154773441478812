import React from "react";
import { useDispatch } from "react-redux";

import {
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  SignInModes,
  SignInScreens,
  backToRequestEmail,
  checkUserExists,
  closeSignIn,
  sendSignInEmail,
  signInLaunchDone,
  useSignInLaunchState,
} from "../../../store/signIn";
import { theme } from "../../Theme";
import { EmailInput, Link, NameInput } from "../../atoms";
import { SnackbarSeverity, showSnackbar } from "../../store/snackbar";
import { VerifyEmailScreen } from "./VerifyEmailDialog";

const EMAIL_FIELD_ID = "sign-in-user-email";
const NAME_FIELD_ID = "sign-in-user-name";

const useStyles = makeStyles((theme) => ({
  pending: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
  },
  nameScreenContent: {
    "&.MuiDialogContent-root": {
      paddingTop: theme.spacing(1),
    },
    textAlign: "left",
  },
  termsCheckbox: {
    marginTop: theme.spacing(1),
  },
}));

function EmailScreen({ open, close, email, setEmail }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { pending } = useSignInLaunchState();

  const handleNext = () => {
    if (!email || !document.getElementById(EMAIL_FIELD_ID).validity.valid) {
      dispatch(
        showSnackbar("Please enter a valid email", SnackbarSeverity.INFO)
      );
    } else if (!pending) {
      dispatch(checkUserExists(email));
    }
  };

  return (
    <Collapse in={open}>
      <DialogTitle id="sign-in-dialog-title">
        Sign in with your email
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={(theme) => ({ marginBottom: theme.spacing(2) })}>
          If this is your first time, signing in will sign you up
        </DialogContentText>
        <EmailInput
          value={email}
          setValue={setEmail}
          onPressEnter={handleNext}
          autoFocus
          id={EMAIL_FIELD_ID}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="secondary" tabIndex={4}>
          Cancel
        </Button>
        <Button onClick={handleNext} color="primary">
          {pending && (
            <CircularProgress
              className={classes.pending}
              size={theme.spacing(2)}
            />
          )}
          Next
        </Button>
      </DialogActions>
    </Collapse>
  );
}

function NameScreen({ open, name, setName, doSignUp, doBack }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { pending } = useSignInLaunchState();
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const handleTermsAcceptedChange = (event) =>
    setTermsAccepted(event.target.checked);

  const handleSignUp = () => {
    if (!name) {
      dispatch(showSnackbar("Please enter your name", SnackbarSeverity.INFO));
    } else if (!termsAccepted) {
      dispatch(
        showSnackbar(
          "You need to accept Maeva's terms to use our service",
          SnackbarSeverity.INFO
        )
      );
    } else if (!pending) {
      doSignUp();
    }
  };

  const TermsLink = ({ href, children }) => (
    <Link to={href} target="_blank" underline="always">
      {children}
    </Link>
  );

  return (
    <Collapse in={open}>
      <DialogTitle id="sign-in-dialog-title">
        Finally, please enter your name
      </DialogTitle>
      <DialogContent className={classes.nameScreenContent}>
        <NameInput
          value={name}
          setValue={setName}
          onPressEnter={handleSignUp}
          autoFocus
          id={NAME_FIELD_ID}
          fullWidth
        />
        <FormControlLabel
          className={classes.termsCheckbox}
          control={
            <Checkbox
              checked={termsAccepted}
              onChange={handleTermsAcceptedChange}
            />
          }
          label={
            <>
              {"I agree to Maeva's "}
              <TermsLink href="/terms">Terms of Service</TermsLink>
              {" and "}
              <TermsLink href="/privacy">Privacy Policy</TermsLink>
            </>
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={doBack} color="secondary" tabIndex={4}>
          Back
        </Button>
        <Button onClick={handleSignUp} color="primary">
          {pending && (
            <CircularProgress
              className={classes.pending}
              size={theme.spacing(2)}
            />
          )}
          Sign Up
        </Button>
      </DialogActions>
    </Collapse>
  );
}

export default function SignInDialog() {
  const dispatch = useDispatch();
  const { activeScreen, mode } = useSignInLaunchState();
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");

  const close = () => {
    // Close before resetting the UI
    dispatch(closeSignIn());
    setTimeout(() => {
      dispatch(signInLaunchDone());
      setName("");
      setEmail("");
    }, 0);
  };

  const doSignUp = () =>
    dispatch(sendSignInEmail(SignInModes.signUp, email, name));

  const doBack = () => dispatch(backToRequestEmail());

  React.useEffect(() => {
    if (activeScreen === SignInScreens.RequestEmail) {
      setTimeout(() => document.getElementById(EMAIL_FIELD_ID)?.focus(), 0);
    } else if (activeScreen === SignInScreens.RequestName) {
      setTimeout(() => document.getElementById(NAME_FIELD_ID)?.focus(), 0);
    }
  }, [activeScreen]);

  return (
    <Dialog
      open={activeScreen !== null}
      onClose={close}
      disableEscapeKeyDown
      aria-labelledby="sign-in-dialog-title"
      aria-describedby="sign-in-dialog-description"
      style={{
        textAlign: "center",
      }}
    >
      <EmailScreen
        open={activeScreen === SignInScreens.RequestEmail}
        close={close}
        email={email}
        setEmail={setEmail}
      />
      <NameScreen
        open={activeScreen === SignInScreens.RequestName}
        name={name}
        doSignUp={doSignUp}
        doBack={doBack}
        setName={setName}
      />
      <VerifyEmailScreen
        open={activeScreen === SignInScreens.Verify}
        close={close}
        mode={mode}
      />
    </Dialog>
  );
}
