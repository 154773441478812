import React from "react";

import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { Rating as MuiRating } from "@mui/material";

import { theme } from "../../lib/Theme";

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
});

const StyledMuiRating = withStyles({
  root: {
    alignItems: "center",
  },
  iconFilled: {
    color: theme.palette.secondary.main,
  },
})(MuiRating);

export default function Rating({ ratingCount = 0, ...ratingProps }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <StyledMuiRating {...ratingProps} />
      {!!ratingCount && (
        <Typography variant="body1" color="secondary">
          ({ratingCount})
        </Typography>
      )}
    </div>
  );
}
