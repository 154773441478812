import React from "react";

import { Typography } from "@mui/material";
import { ToggleButton } from "@mui/material";

import { ToggleButtonGroup } from "../atoms";

// const useStyles = makeStyles((theme) => ({
//   addExcludes: {
//     border: "1px solid rgba(0, 0, 0, 0.12)",
//     backgroundColor: theme.palette.light.main,
//     fontWeight: 500,
//     lineHeight: 1.75,
//     fontSize: "0.875rem",
//     width: theme.spacing(14),
//     borderRadius: 4,
//     marginRight: theme.spacing(1),
//     marginTop: theme.spacing(0.5),
//     marginBottom: theme.spacing(0.5),
//     padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
//     "& input": {
//       height: "auto",
//       padding: 0,
//     },
//   },
// }));

export default function ExcludedIngredientsSelector({ value, onChange }) {
  // const classes = useStyles();
  const excludable = [
    "Fragrance",
    "Silicones",
    "Oxybenzone",
    "Alcohol denat",
    "Essential oils",
    "Homosalate",
    "Octinoxate",
  ];
  const handleChange = (event, newValue) => onChange(newValue);

  return (
    <>
      <Typography
        id="excluding-label"
        variant="subtitle1"
        color="secondary"
        gutterBottom
      >
        Without these ingredients
      </Typography>
      <ToggleButtonGroup
        value={value}
        onChange={handleChange}
        aria-labelledby="excluding-label"
      >
        {excludable.map((item, i) => (
          <ToggleButton key={i} value={item}>
            {item}
          </ToggleButton>
        ))}
        {/* <InputBase
          className={classes.addExcludes}
          placeholder="Add your own"
          inputProps={{
            "aria-label": "Add your own",
            name: "add-excludes",
          }}
          type="text"
          // value={name}
          // onChange={handleChangeName}
          // onKeyPress={handleOnPressEnter}
        /> */}
      </ToggleButtonGroup>
    </>
  );
}
