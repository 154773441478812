import React from "react";
import { useDispatch } from "react-redux";

import { useMaevaUIState } from "../store";
import { hideSnackbar } from "../store/snackbar";
import Snackbar from "./Snackbar";

export default function GlobalSnackbar() {
  const dispatch = useDispatch();
  // TODO use proper selector
  const {
    snackbar: { shown, message, severity },
  } = useMaevaUIState();

  const handleClose = (event, reason) => {
    if (reason !== "clickaway") {
      dispatch(hideSnackbar());
    }
  };

  return (
    <Snackbar
      open={shown}
      onClose={handleClose}
      severity={severity}
      duration={null}
    >
      {message}
    </Snackbar>
  );
}
