import { getMetaSetter } from "@maeva/jscommon/metaTags";

export default function Page({
  title,
  description,
  keywords,
  metaImageUrl,
  metaImageAlt,
  children,
}) {
  getMetaSetter(document)({
    url: window.location.href,
    title,
    description,
    keywords,
    metaImageUrl,
    metaImageAlt,
  });

  return children;
}
