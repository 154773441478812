import React from "react";

import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material";

const fonts = [
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];
export const primaryFontFamily = ["Noto Sans"].concat(fonts).join(",");
export const secondaryFontFamily = ["Manrope"].concat(fonts).join(",");

const palette = {
  primary: {
    main: "#ff9c96", // Watermelon
    light: "#ffbba8", // Pale watermelon
    lighter: "#fff5f3",
  },
  secondary: {
    main: "#705555", // Aubergine
    hightlight: "#806060",
  },
  dark: {
    main: "#3f3f3f", // Charcoal
    light: "#bfbfbf",
  },
  light: {
    main: "#f9f9f9", // Subtle grey
  },
  white: {
    main: "#fff",
  },
  background: {
    default: "#fff",
  },
};

const anchorStyle = {
  color: palette.secondary.main,
  textDecorationColor: palette.secondary.main,
  "&:active": {
    color: palette.primary.main,
  },
};

export const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@global": {
          a: anchorStyle,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: anchorStyle,
      },
    },
  },
  palette,
  spacing: (factor) => `${0.5 * factor}rem`,
  typography: {
    fontFamily: secondaryFontFamily,
    h1: {
      fontFamily: primaryFontFamily,
      fontWeight: "bold",
      marginBottom: "1rem",
    },
    h2: {
      fontFamily: primaryFontFamily,
      fontWeight: "bold",
      marginBottom: "1rem",
    },
    h3: {
      fontFamily: primaryFontFamily,
      fontWeight: "bold",
      marginBottom: "1rem",
    },
    h4: {
      fontFamily: primaryFontFamily,
      fontWeight: "bold",
      marginBottom: "1rem",
    },
  },
});

export function withTheme(Component) {
  return (props) => (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Component {...props} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default function MeavaThemeProvider({ children }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
}
