import React from "react";
import { useDispatch } from "react-redux";

import firebase from "../../firebase";
import { signedIn, signedOut } from "./slice";

export function AuthStateChangedListener() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          dispatch(signedIn());
        } else {
          dispatch(signedOut());
        }
      });
    return () => unregisterAuthObserver();
  }, [dispatch]);

  return null;
}
