import { useSelector } from "react-redux";

export function useSignInState() {
  return useSelector((state) => state.signIn);
}

export function useSignInLaunchState() {
  return useSelector((state) => state.signIn.launch);
}

export function useSignInValidationState() {
  return useSelector((state) => state.signIn.validation);
}
