import React from "react";

import { Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import BugReportIcon from "@mui/icons-material/BugReport";

import { Button } from "../../lib/atoms";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "100%",
    padding: `20% ${theme.spacing(4)}`,
    backgroundColor: theme.palette.light.main,
    "& h2": {
      marginBottom: theme.spacing(6),
    },
    "& p": {
      marginTop: theme.spacing(6),
    },
  },
  icon: {
    fontSize: 100,
    color: theme.palette.secondary.main,
  },
});

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    const { classes } = this.props;
    const reload = () => window.location.reload();

    if (this.state.hasError) {
      return (
        <div className={classes.root}>
          <BugReportIcon className={classes.icon} />
          <Typography variant="h2" component="h1">
            Oops, our bad!
          </Typography>
          <Typography variant="h3" component="h2">
            We're really sorry, something broke
          </Typography>
          <Button variant="contained" color="primary" onClick={reload}>
            Reload
          </Button>
          <Typography variant="subtitle1" component="p">
            If reloading doesn't work or you want to help us track down the bug,
            give us a shout at {process.env.REACT_APP_CONTACT_EMAIL}!
          </Typography>
        </div>
      );
    }

    return this.props.children;
  }
}

export const GlobalErrorBoundary = withStyles(styles, { withTheme: true })(
  ErrorBoundary
);
