import { ofType } from "redux-observable";
import { catchError, map, mergeMap, of, switchMap, withLatestFrom } from "rxjs";

import { SnackbarSeverity, showSnackbar } from "../../../lib/store/snackbar";
import {
  catchLaunchSignInError,
  reauthenticate,
  resendSignInEmail,
  sendSignInEmail,
  signInEmailSent,
} from "../slice";
import { SignInModes } from "..";

export default (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(sendSignInEmail, resendSignInEmail, reauthenticate),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      of(action).pipe(
        switchMap(() => {
          const { mode } = action.payload;
          let email, name;

          if (action.type === reauthenticate.type) {
            email = firebase.auth().currentUser.email;
            name = null;
          } else {
            email = state.signIn.email;
            name = state.signIn.name;
          }

          const actionCodeSettings = {
            // URL to redirect back to from the email.
            // The domain must be in the authorised domains list in the Firebase Console.
            // We have to use "type" instead of "mode" as firebase already includes a "mode"
            // parameter that isn't as granular as we need.
            url: `${process.env.REACT_APP_ORIGIN}?type=${mode}`,
            handleCodeInApp: true, // This must be true.
          };

          if (name) {
            const params = new URLSearchParams({ name });
            actionCodeSettings.url += `&${params.toString()}`;
          }

          return firebase
            .auth()
            .sendSignInLinkToEmail(email.trim(), actionCodeSettings);
        }),
        map(() => {
          const { mode } = action.payload;
          const type = mode === SignInModes.signUp ? "sign_up" : "sign_in";
          let email, name;

          if (action.type === reauthenticate.type) {
            email = firebase.auth().currentUser.email;
            name = null;
          } else {
            email = state.signIn.email;
            name = state.signIn.name;
          }

          firebase
            .analytics()
            .logEvent(type, { method: "EmailLink", mode, email, name });

          return signInEmailSent();
        }),
        catchError((error) => {
          console.error(`Error ${error.code}:`, error.message);
          return [
            showSnackbar(
              "Something went wrong, please try again",
              SnackbarSeverity.ERROR
            ),
            catchLaunchSignInError(),
          ];
        })
      )
    )
  );
