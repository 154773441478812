import { ofType } from "redux-observable";
import { catchError, from, mergeMap, of } from "rxjs";

import { SnackbarSeverity, showSnackbar } from "../../../lib/store/snackbar";
import { SignInModes } from "../constants";
import {
  askForName,
  catchLaunchSignInError,
  checkUserExists,
  sendSignInEmail,
} from "../slice";

export default (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(checkUserExists),
    mergeMap((action) =>
      of(action).pipe(
        mergeMap(async ({ payload: { email } }) => {
          const result = await firebase
            .auth()
            .fetchSignInMethodsForEmail(email.trim());

          if (result.length) {
            return [sendSignInEmail(SignInModes.signIn, email)];
          } else {
            return [askForName()];
          }
        }),
        mergeMap((promise) => from(promise)),
        catchError((error) => {
          console.error("Error checking user exists:", error);
          return [
            showSnackbar(
              "Something went wrong, please try again",
              SnackbarSeverity.ERROR
            ),
            catchLaunchSignInError(),
          ];
        })
      )
    )
  );
