import { ofType } from "redux-observable";
import { catchError, mergeMap, of, switchMap, withLatestFrom } from "rxjs";

import { SnackbarSeverity, showSnackbar } from "../../../lib/store/snackbar";
import {
  openConfirmDeletionScreen,
  openUpdateEmailScreen,
} from "../../profile";
import { SignInModes } from "../constants";
import {
  catchValidateSignInError,
  clearSignInEmail,
  createUser,
  finishSignIn,
  validateSignIn,
} from "../slice";

export default (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(validateSignIn),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      of(action).pipe(
        switchMap(() =>
          firebase
            .auth()
            .signInWithEmailLink(state.signIn.email, window.location.href)
        ),
        mergeMap((result) => {
          if (result.additionalUserInfo.isNewUser) {
            return [createUser()];
          }

          const mode = state.signIn.validation.mode;
          if (mode === SignInModes.reauthForEmail) {
            return [
              showSnackbar(
                "Success! You can update your email now",
                SnackbarSeverity.SUCCESS
              ),
              openUpdateEmailScreen(),
              finishSignIn(),
            ];
          } else if (mode === SignInModes.reauthForDeletion) {
            return [
              showSnackbar(
                "Signed in. You can delete your account now",
                SnackbarSeverity.INFO
              ),
              openConfirmDeletionScreen(),
              finishSignIn(),
            ];
          }

          return [finishSignIn()];
        }),
        catchError((error) => {
          switch (error.code) {
            case "auth/invalid-email": {
              return [
                showSnackbar(
                  "The email you entered does not match the email you're signing in with",
                  SnackbarSeverity.ERROR
                ),
                clearSignInEmail(),
                catchValidateSignInError(),
              ];
            }
            case "auth/invalid-action-code": {
              return [
                showSnackbar(
                  "Oops, your sign-in link has expired. Try signing in again.",
                  SnackbarSeverity.ERROR
                ),
                catchValidateSignInError(),
                finishSignIn(),
              ];
            }
            default: {
              console.error(`Error ${error.code}:`, error.message);
              return [
                showSnackbar(
                  "Something unexpected happened, maybe try entering your email again?",
                  SnackbarSeverity.ERROR
                ),
                catchValidateSignInError(),
              ];
            }
          }
        })
      )
    )
  );
