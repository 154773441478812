import classNames from "classnames";
import React from "react";

import {
  FormControl,
  InputBase,
  InputLabel,
  Select as MuiSelect,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";

import { theme } from "../Theme";

const styles = {
  root: {
    padding: theme.spacing(1, 2),
    borderRadius: 10,
    marginBottom: theme.spacing(2),
    background: "white",
    width: theme.spacing(30),
    height: theme.spacing(6),
    boxSizing: "border-box",
    textAlign: "left",
    "&>*": {
      top: "50%",
      transform: "translateY(-50%)",
    },
    "& label": {
      left: theme.spacing(2),
      opacity: 1,
      "&.MuiInputLabel-shrink": {
        opacity: "0",
      },
    },
    "& .MuiSelect-root": {
      marginTop: 1,
      "&:focus": {
        borderRadius: 5,
      },
    },
  },
  inline: {
    marginBottom: 0,
  },
  menuList: {
    "& li": {
      minHeight: 32,
      "& .MuiCheckbox-root": {
        padding: `0 ${theme.spacing(1)}`,
      },
    },
  },
};

function Select(props) {
  const {
    classes,
    className,
    inline = false,
    labelProps = {},
    formControlProps,
    menuClassName,
    children,
    ...selectProps
  } = props;
  const { children: labelChildren, ...restLabelProps } = labelProps;
  let formControlClass = undefined;
  let restFormControlProps = formControlProps;

  if (formControlProps && "className" in formControlProps) {
    formControlClass = formControlProps.className;
    delete formControlProps.className;
  }

  return (
    <FormControl
      className={classNames(
        "maeva-select",
        classes.root,
        formControlClass,
        {
          [classes.inline]: inline,
        },
        className
      )}
      {...restFormControlProps}
    >
      {labelChildren && (
        <InputLabel {...restLabelProps}>{labelChildren}</InputLabel>
      )}
      <MuiSelect
        input={<InputBase />}
        MenuProps={{
          className: menuClassName,
          classes: { list: classes.menuList },
        }}
        {...selectProps}
      >
        {children}
      </MuiSelect>
    </FormControl>
  );
}

export default withStyles(styles)(Select);
