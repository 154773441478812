import classNames from "classnames";
import React from "react";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 200,
    padding: theme.spacing(6),
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    "& h2": {
      marginBottom: theme.spacing(2),
    },
  },
}));

export default function Section({ className, children }) {
  const classes = useStyles();
  return (
    <section className={classNames(classes.root, className)}>
      {children}
    </section>
  );
}
