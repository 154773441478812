import { ofType } from "redux-observable";
import { filter, map, mergeMap, of, withLatestFrom } from "rxjs";

import { reaffirmSignInEmail, startSignIn, validateSignIn } from "../slice";

export default (action$, state$) =>
  action$.pipe(
    ofType(startSignIn, reaffirmSignInEmail),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      of(action).pipe(
        filter(() => !!state.signIn.email),
        map(() => validateSignIn())
      )
    )
  );
