import React from "react";

import { Snackbar as MuiSnackbar } from "@mui/material";

import { SNACKBAR_AUTOHIDE_DURATION } from "../config";
import { Alert } from "./";

export default function Snackbar(props) {
  const {
    onClose,
    children,
    severity,
    duration = SNACKBAR_AUTOHIDE_DURATION,
    ...rest
  } = props;

  const handleClose = (event, reason) => {
    if (reason !== "clickaway") {
      onClose(event, reason);
    }
  };

  return (
    <MuiSnackbar
      autoHideDuration={duration}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      {...rest}
    >
      <div>
        <Alert onClose={handleClose} severity={severity}>
          {children}
        </Alert>
      </div>
    </MuiSnackbar>
  );
}
