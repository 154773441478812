import classNames from "classnames";
import React from "react";

import { Slider as MuiSlider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { theme } from "../../lib/Theme";

const useStyles = makeStyles({
  wrapper: {
    padding: theme.spacing(1),
  },
  root: {
    "&.MuiSlider-colorPrimary .MuiSlider-valueLabel span": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white.main,
    },
    "&.MuiSlider-colorSecondary .MuiSlider-valueLabel span": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.white.main,
    },
  },
  thumb: {
    width: 18,
    height: 18,
    backgroundColor: theme.palette.white.main,
    border: `2px solid ${theme.palette.secondary.main}`,
    "&.Mui-focusVisible": {
      boxShadow: "0px 0px 0px 6px rgb(112 85 85 / 16%)",
    },
    "&.MuiSlider-active": {
      boxShadow: "0px 0px 0px 8px rgb(112 85 85 / 16%)",
    },
  },
});

export default function Slider(props) {
  const { className, classes, ...rest } = props;
  const { wrapper: wrapperClass, ...defaultClasses } = useStyles();
  return (
    <div className={classNames(wrapperClass, { [className]: className })}>
      <MuiSlider classes={{ ...defaultClasses, ...classes }} {...rest} />
    </div>
  );
}
