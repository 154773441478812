import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { Link as MUILink } from "@mui/material";

/**
 * Link that renders either as a Material UI or React Router component.
 *
 * Automatically inserts `rel="noopener noreferrer"` for `_target="blank"`.
 *
 * @param {string} href: external URL; uses the MUI `Link` component
 * @param {string, object} to: internal URL, uses the React Router `Link` component.
 */
export default function Link({ href, to, target, rel, children, ...rest }) {
  const Component = href ? MUILink : RouterLink;
  const props = href ? { href } : { to };

  if (target === "_blank" && !rel) {
    rel = "noopener noreferrer";
  }

  return (
    <MUILink
      component={Component}
      target={target}
      rel={rel}
      {...props}
      {...rest}
    >
      {children}
    </MUILink>
  );
}
