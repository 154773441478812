import React from "react";

import { CircularProgress } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1 1 auto",
    height: "100%",
    margin: "auto",
  },
});

export default function Spinner(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress {...props} />
    </div>
  );
}
