import React from "react";

import { TextField } from "@mui/material";

export default function EmailInput({
  value,
  setValue,
  onPressEnter,
  Component = TextField,
  ...props
}) {
  const handleChangeEmail = (event) => setValue(event.target.value);
  const handleOnPressEnter = (event) => {
    if (event.key === "Enter" && onPressEnter) {
      onPressEnter();
    }
  };

  if (Component === TextField) {
    props.label = "Email";
  }

  return (
    <Component
      type="email"
      inputProps={{
        "aria-label": "Email",
        name: "email",
      }}
      required
      value={value}
      onChange={handleChangeEmail}
      onKeyPress={handleOnPressEnter}
      {...props}
    />
  );
}
