import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { AuthStateChangedListener } from "./auth";
import createStore from "./createStore";
import { SignInListener } from "./signIn/components";

export const { store, persistor } = createStore();

export function StoreProvider({ children }) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthStateChangedListener />
        <SignInListener />
        {children}
      </PersistGate>
    </Provider>
  );
}
