import { ofType } from "redux-observable";
import { catchError, ignoreElements, mergeMap, of, switchMap } from "rxjs";

import { signedIn } from "../slice";

export default (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(signedIn),
    mergeMap((action) =>
      of(action).pipe(
        switchMap(() => {
          const user = firebase.auth().currentUser;
          const timestamp = new Date().getTime();

          return firebase.firestore().collection("users").doc(user.uid).set(
            {
              email: user.email, // TODO remove once early users' profiles have been updated
              name: user.displayName, // TODO remove once early users' profiles have been updated
              lastLogin: timestamp,
            },
            { merge: true }
          );
        }),
        ignoreElements(),
        catchError((error) => {
          console.error(`Error during sign in:`, error);
          return [];
        })
      )
    )
  );
