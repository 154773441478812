import firebase from "firebase/app";

/**
 * Fetches a brand from Firestore by id
 * @param {String} id The brand id
 * @returns The brand data
 */
export async function fetchBrandById(id) {
  try {
    const firestore = firebase.firestore();
    const brands = firestore.collection("brands");
    const doc = await brands.doc(id).get();

    if (doc.exists) {
      return doc.data();
    } else {
      console.error(`Found no brand with id: "${id}"`);
    }
  } catch (error) {
    console.error(`Failed to fetch Firestore brand: ${error}`);
  }

  return null;
}

export async function fetchBrands() {
  try {
    const firestore = firebase.firestore();
    const brands = firestore.collection("brands");
    const docs = await brands.get();

    if (docs && docs.size > 0) {
      return docs.docs.map((doc) => doc.data());
    } else {
      console.error("Found no brands");
    }
  } catch (error) {
    console.error(`Failed to fetch Firestore brands: ${error}`);
  }

  return [];
}
