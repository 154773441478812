import React from "react";
import { useDispatch } from "react-redux";

import firebase from "../../firebase";
import { useAuthState } from "../auth";
import { startSignIn } from "./slice";

export function SignInListener() {
  const dispatch = useDispatch();
  const { hasLoaded } = useAuthState();

  React.useEffect(() => {
    if (
      hasLoaded &&
      firebase.auth().isSignInWithEmailLink(window.location.href)
    ) {
      dispatch(startSignIn());
    }
  }, [dispatch, hasLoaded]);

  return null;
}
