import React from "react";
import { useDispatch } from "react-redux";

import {
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PublicIcon from "@mui/icons-material/Public";

import { useUser } from "../../store/auth";
import {
  ProfileScreens,
  closeProfile,
  deleteUser,
  openConfirmDeletionScreen,
  openProfile,
  openUpdateEmailScreen,
  openUpdateNameScreen,
  updateEmail,
  updateUsername,
  useProfileState,
} from "../../store/profile";
import { theme } from "../Theme";
import { EmailInput, NameInput } from "../atoms";
import { SnackbarSeverity, showSnackbar } from "../store/snackbar";
import { VerifyEmailScreen } from "./auth/VerifyEmailDialog";

const EMAIL_FIELD_ID = "profile-user-email";
const NAME_FIELD_ID = "profile-user-name";

const useStyles = makeStyles((theme) => ({
  bigIcon: {
    fontSize: 100,
    color: theme.palette.secondary.main,
  },
  pending: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
  },
}));

function ProfileScreen() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { activeScreen } = useProfileState();
  const user = useUser();

  const goUpdateName = () => {
    dispatch(openUpdateNameScreen());
    setTimeout(() => document.getElementById(NAME_FIELD_ID).focus(), 0);
  };
  const goUpdateEmail = () => {
    dispatch(openUpdateEmailScreen());
    setTimeout(() => document.getElementById(EMAIL_FIELD_ID).focus(), 0);
  };
  const goConfirmDeletion = () => dispatch(openConfirmDeletionScreen());
  const close = () => dispatch(closeProfile());

  return user ? (
    <Collapse in={activeScreen === ProfileScreens.Profile}>
      <DialogTitle id="profile-dialog-title">Profile</DialogTitle>
      <DialogContent id="profile-dialog-description">
        <DialogContentText>
          Hi, <strong>{user.displayName}</strong>! Thank you for being a part of
          the Maeva community!
        </DialogContentText>
        <PublicIcon className={classes.bigIcon} />
        <DialogContentText>
          We plan to expand profiles in the future, but for now you can use this
          to update your account details
        </DialogContentText>
        <DialogContentText>
          Your registered email address is: <strong>{user.email}</strong>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={goUpdateName} color="secondary">
          Update Name
        </Button>
        <Button onClick={goUpdateEmail} color="secondary">
          Update Email
        </Button>
        <Button onClick={goConfirmDeletion} color="secondary">
          Delete account
        </Button>
        <Button onClick={close} color="primary">
          Done
        </Button>
      </DialogActions>
    </Collapse>
  ) : null;
}

function UpdateNameScreen() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { activeScreen, pending } = useProfileState();
  const [name, setName] = React.useState("");

  const handleGoBack = () => {
    setName("");
    dispatch(openProfile());
  };

  const handleUpdate = () => dispatch(updateUsername(name));

  React.useEffect(() => {
    // Reset name when we're done
    if (name && activeScreen === ProfileScreens.Profile) {
      setName("");
    }
  }, [name, activeScreen]);

  return (
    <Collapse in={activeScreen === ProfileScreens.UpdateName}>
      <DialogTitle id="profile-dialog-title">Update Name</DialogTitle>
      <DialogContent id="profile-dialog-description">
        <DialogContentText>Enter your new name</DialogContentText>
        <NameInput
          value={name}
          setValue={setName}
          onPressEnter={handleUpdate}
          id={NAME_FIELD_ID}
          autoFocus
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleGoBack} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleUpdate} color="primary">
          {pending && (
            <CircularProgress
              className={classes.pending}
              size={theme.spacing(2)}
            />
          )}
          Update
        </Button>
      </DialogActions>
    </Collapse>
  );
}

function UpdateEmailScreen() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useUser();
  const { activeScreen, pending } = useProfileState();
  const [email, setEmail] = React.useState("");

  const handleGoBack = () => {
    setEmail("");
    dispatch(openProfile());
  };

  const handleUpdate = () => {
    if (!email || !document.getElementById(EMAIL_FIELD_ID).validity.valid) {
      dispatch(
        showSnackbar("Please enter a valid email", SnackbarSeverity.ERROR)
      );
    } else if (email === user.email) {
      dispatch(
        showSnackbar(
          "You're already using that email address",
          SnackbarSeverity.ERROR
        )
      );
    } else {
      dispatch(updateEmail(email));
    }
  };

  return (
    <Collapse in={activeScreen === ProfileScreens.UpdateEmail}>
      <DialogTitle id="profile-dialog-title">Update Email Address</DialogTitle>
      <DialogContent id="profile-dialog-description">
        <DialogContentText>Enter your email</DialogContentText>
        <EmailInput
          value={email}
          setValue={setEmail}
          onPressEnter={handleUpdate}
          id={EMAIL_FIELD_ID}
          autoFocus
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleGoBack} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleUpdate} color="primary">
          {pending && (
            <CircularProgress
              className={classes.pending}
              size={theme.spacing(2)}
            />
          )}
          Update
        </Button>
      </DialogActions>
    </Collapse>
  );
}

function ConfirmDeletionScreen() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { activeScreen, pending } = useProfileState();

  const cancel = () => dispatch(openProfile());
  const handleDelete = () => dispatch(deleteUser());

  return (
    <Collapse in={activeScreen === ProfileScreens.ConfirmDeletion}>
      <DialogTitle id="profile-dialog-title">
        Are you sure you want to delete your account?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="profile-dialog-description">
          Warning! We will delete your account immediately, you can't get it
          back!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="primary">
          {pending && (
            <CircularProgress
              className={classes.pending}
              size={theme.spacing(2)}
            />
          )}
          Delete
        </Button>
      </DialogActions>
    </Collapse>
  );
}

export default function ProfileDialog() {
  const dispatch = useDispatch();
  const { activeScreen, reauthMode } = useProfileState();
  const close = () => dispatch(closeProfile());

  return (
    <Dialog
      open={activeScreen !== null}
      onClose={close}
      aria-labelledby="profile-dialog-title"
      aria-describedby="profile-dialog-description"
      style={{
        textAlign: "center",
      }}
    >
      <ProfileScreen />
      <UpdateNameScreen />
      <UpdateEmailScreen />
      <ConfirmDeletionScreen />
      <VerifyEmailScreen
        open={activeScreen === ProfileScreens.VerifyEmail}
        close={close}
        mode={reauthMode}
      />
    </Dialog>
  );
}
