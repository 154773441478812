import React from "react";
import { useDispatch } from "react-redux";

import {
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DraftsIcon from "@mui/icons-material/Drafts";

import { useUser } from "../../../store/auth";
import {
  SignInModes,
  resendSignInEmail,
  useSignInLaunchState,
} from "../../../store/signIn";
import { theme } from "../../Theme";

const useStyles = makeStyles((theme) => ({
  bigIcon: {
    fontSize: 100,
    color: theme.palette.secondary.main,
  },
  pending: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
  },
}));

function SignUpDialogContent() {
  const classes = useStyles();
  return (
    <>
      <DialogTitle id="verify-email-dialog-title">
        Check your email to verify your account
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="verify-email-dialog-description">
          Thank you for registering to join maeva!
        </DialogContentText>
        <DraftsIcon className={classes.bigIcon} />
        <DialogContentText>
          We sent you an email from{" "}
          <strong>{process.env.REACT_APP_NOREPLY_EMAIL}</strong>, so please make
          sure it's not filtered out.
        </DialogContentText>
      </DialogContent>
    </>
  );
}

function SignInDialogContent() {
  const classes = useStyles();
  return (
    <>
      <DialogTitle id="verify-email-dialog-title">
        Check your email for a sign in link
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="verify-email-dialog-description">
          We sent you an email with a one-time link that will verify your sign
          in.
        </DialogContentText>
        <DraftsIcon className={classes.bigIcon} />
        <DialogContentText>
          The email is from{" "}
          <strong>{process.env.REACT_APP_NOREPLY_EMAIL}</strong>, so please make
          sure it's not filtered out.
        </DialogContentText>
      </DialogContent>
    </>
  );
}

function ReauthDialogContent() {
  const classes = useStyles();
  const user = useUser();
  const email = user?.email || "you";
  return (
    <>
      <DialogTitle id="verify-email-dialog-title">
        Check your email for a verification link
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="verify-email-dialog-description">
          It's been a while since you last signed in so we sent an email to{" "}
          <strong>{email}</strong> with a one-time link that will
          re-authenticate you for this action.
        </DialogContentText>
        <DraftsIcon className={classes.bigIcon} />
        <DialogContentText>
          The email is from{" "}
          <strong>{process.env.REACT_APP_NOREPLY_EMAIL}</strong>, so please make
          sure it's not filtered out.
        </DialogContentText>
      </DialogContent>
    </>
  );
}

export function VerifyEmailScreen({ open, close, mode }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pending } = useSignInLaunchState();
  const resend = () => dispatch(resendSignInEmail(mode));

  return (
    <Collapse in={open}>
      {mode === SignInModes.signUp && <SignUpDialogContent />}
      {mode === SignInModes.signIn && <SignInDialogContent />}
      {[SignInModes.reauthForEmail, SignInModes.reauthForDeletion].includes(
        mode
      ) && <ReauthDialogContent />}
      <DialogActions>
        <Button onClick={resend} color="secondary">
          {pending && (
            <CircularProgress
              className={classes.pending}
              size={theme.spacing(2)}
            />
          )}
          Resend Email
        </Button>
        <Button onClick={close} color="primary">
          Done
        </Button>
      </DialogActions>
    </Collapse>
  );
}

export default function VerifyEmailDialog({ open, close, mode }) {
  const handleClose = (event, reason) => reason !== "backdropClick" && close();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown
      aria-labelledby="verify-email-dialog-title"
      aria-describedby="verify-email-dialog-description"
      style={{
        textAlign: "center",
      }}
    >
      <VerifyEmailScreen open={open} close={handleClose} mode={mode} />
    </Dialog>
  );
}
