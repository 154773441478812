import algoliasearch from "algoliasearch";
import firebase from "firebase/app";

import { queryClient } from "../webapp/src/utils/queryClient";
import { fetchBrands } from "./brand";
import { fetchVendors } from "./vendor";

const AlgoliaIndexes =
  process.env.NODE_ENV === "production"
    ? Object.freeze({
        PopularityDesc: "variants",
        PriceDesc: "variants_price_desc",
        PriceAsc: "variants_price_asc",
      })
    : Object.freeze({
        PopularityDesc: "variants__dev",
        PriceDesc: "variants_price_desc__dev",
        PriceAsc: "variants_price_asc__dev",
      });

export const SPFType = Object.freeze({
  All: "All",
  Mineral: "Mineral",
  Chemical: "Chemical",
  Hybrid: "Hybrid",
});

/**
 * Gets the Algolia index name based on the sort type
 * @param {String} sort The sort type
 * @returns The Algolia index name
 */
function getAlgoliaIndexName(sort) {
  return {
    popularity_desc: AlgoliaIndexes.PopularityDesc,
    price_desc: AlgoliaIndexes.PriceDesc,
    price_asc: AlgoliaIndexes.PriceAsc,
  }[sort];
}

/**
 * Gets the Algolia index object based on the sort type
 * @param {String} sort The sort type
 * @returns The Algolia index object
 */
function getAlgoliaIndex(sort) {
  const client = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_SEARCH_KEY
  );
  const indexName = getAlgoliaIndexName(sort);
  return client.initIndex(indexName);
}

/**
 * Generates the Algolia search options from search parameters
 * @param {String} spfType The SPFType
 * @param {Array} spfRange The SPF range
 * @param {Array} priceRange The price range
 * @param {Array} excludes The excluded ingredients
 * @param {Number} page The page number
 * @returns The Algolia search options object
 */
async function getAlgoliaOptions({
  spfType,
  spfRange,
  priceRange,
  excludes,
  vendorIds,
  brandIds,
  page,
}) {
  const numericFilters = ["searchable=1", "hidden=0"];
  const tagFilters = [];
  const facetFilters = [];

  if (spfType === SPFType.Mineral) {
    tagFilters.push("mineral");
    tagFilters.push("-chemical");
  } else if (spfType === SPFType.Chemical) {
    tagFilters.push("-mineral");
    tagFilters.push("chemical");
  } else if (spfType === SPFType.Hybrid) {
    tagFilters.push("hybrid");
  }

  if (spfRange?.length === 2) {
    numericFilters.push(`spf >= ${spfRange[0]}`);
    numericFilters.push(`spf <= ${spfRange[1]}`);
  }

  if (priceRange?.length === 2) {
    numericFilters.push(`price >= ${priceRange[0]}`);
    numericFilters.push(`price <= ${priceRange[1]}`);
  }

  if (excludes?.length > 0) {
    for (const ingredient of excludes) {
      tagFilters.push(`-${ingredient.toLowerCase()}`);
    }
  }

  const createShortestVendorOrBrandFilter = (name, selection, all) => {
    const nTotal = all.length;
    const nSelected = selection.length;

    if (nSelected === nTotal || nSelected === 0) {
      // do nothing
    } else if (nSelected <= nTotal / 2) {
      const andFilters = selection.map((x) => `${name}:${x}`);
      facetFilters.push(andFilters);
    } else {
      all
        .filter((x) => !selection.includes(x.id))
        .forEach((x) => facetFilters.push(`${name}:-${x.id}`));
    }
  };

  if (vendorIds?.length > 0) {
    // TODO update to use `queryClient.ensureQueryData` from `react-query` v4
    let vendorData = queryClient.getQueryData(["vendors"]);

    if (!vendorData) {
      vendorData = queryClient.setQueryData(["vendors"], await fetchVendors());
    }

    createShortestVendorOrBrandFilter("vendors", vendorIds, vendorData);
  }

  if (brandIds?.length > 0) {
    // TODO update to use `queryClient.ensureQueryData` from `react-query` v4
    let brandData = queryClient.getQueryData(["brands"]);

    if (!brandData) {
      brandData = queryClient.setQueryData(["brands"], await fetchBrands());
    }

    createShortestVendorOrBrandFilter("brand_id", brandIds, brandData);
  }

  return { facetFilters, numericFilters, tagFilters, page };
}

/**
 * Searches Algolia for variants
 * @param {String} spfType The SPFType
 * @param {Array} spfRange The SPF range
 * @param {Array} priceRange The price range
 * @param {Array} excludes The excluded ingredients
 * @param {String} sort The sort type
 * @param {Number} page The page number
 * @returns Search results
 */
async function fetchAlgoliaVariants({
  spfType,
  spfRange,
  priceRange,
  excludes,
  vendorIds,
  brandIds,
  sort,
  page = 0,
}) {
  const index = getAlgoliaIndex(sort);
  const options = await getAlgoliaOptions({
    spfType,
    spfRange,
    priceRange,
    excludes,
    vendorIds,
    brandIds,
    page,
  });

  return await index.search("", {
    advancedSyntax: true,
    ...options,
  });
}

/**
 * Fetches variants from Firestore that match an Algolia search
 * @param {Object} results Algolia search results
 * @returns Search results
 */
async function fetchFirestoreVariants(results) {
  if (results.hits.length > 0) {
    try {
      const firestore = firebase.firestore();
      const variants = firestore.collection("variants");
      const docs = await Promise.all(
        results.hits.map((hit) => variants.doc(hit.objectID).get())
      );
      return docs.filter((doc) => doc.exists).map((doc) => doc.data());
    } catch (error) {
      console.error(`Failed to fetch Firestore variants: ${error}`);
    }
  }

  return [];
}

/**
 * Performs a search and returns the result
 * @param {String} spfType The SPFType
 * @param {Array} spfRange The SPF range
 * @param {Array} priceRange The price range
 * @param {Array} excludes The excluded ingredients
 * @param {String} sort The sort type
 * @param {Number} page The page number
 * @param {AbortSignal} signal An optional AbortSignal instance
 * @returns Search results
 */
export async function fetchSearch({
  spfType,
  spfRange,
  priceRange,
  excludes,
  vendorIds,
  brandIds,
  sort,
  page = 0,
  signal,
}) {
  const algoliaResults = await fetchAlgoliaVariants({
    spfType,
    spfRange,
    priceRange,
    excludes,
    vendorIds,
    brandIds,
    sort,
    page,
  });

  // When a user changes multiple search filters in quick succession, requests
  // may be aborted while we await results. We should return early in this case.
  if (signal?.aborted) {
    return;
  }

  const firestoreResults = await fetchFirestoreVariants(algoliaResults);

  // Again, return early if the request was aborted.
  if (signal?.aborted) {
    return;
  }

  // Combine Algolia results with Firestore results
  const hits = firestoreResults.map((item) => ({
    name: algoliaResults.hits.find((hit) => hit.objectID === item.id).name,
    ...item,
  }));

  if (hits && hits.length) {
    return {
      nHits: algoliaResults.nbHits,
      nPages: algoliaResults.nbPages,
      page: algoliaResults.page,
      hits,
    };
  }

  return {
    nHits: 0,
    nPages: 0,
    page: 0,
    hits: [],
  };
}
