import { ofType } from "redux-observable";
import { catchError, mergeMap, of, withLatestFrom } from "rxjs";

import { analytics } from "../../../firebase";
import { SnackbarSeverity, showSnackbar } from "../../../lib/store/snackbar";
import { history } from "../../../utils/history";
import { search } from "../slice";

export default (action$, state$) =>
  action$.pipe(
    ofType(search),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      of(action).pipe(
        mergeMap(() => {
          // Searches are made by pushing new state to history, we do that here.
          // Any components using `useSearchState` will receive updated search
          // state and can choose to call the search API.
          analytics.logEvent("search", state.search.searchState);
          history.push({
            pathname: "/search",
            state: state.search.searchState,
          });
          return [];
        }),
        catchError((error) => {
          console.error("Error executing search:", error);
          return [
            showSnackbar(
              "Search failed, please try again",
              SnackbarSeverity.ERROR
            ),
          ];
        })
      )
    )
  );
