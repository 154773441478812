import React from "react";
import { useDispatch } from "react-redux";

import { analytics } from "../../../firebase";
import { openSignIn } from "../../../store/signIn";
import { Button } from "../../atoms";

const DefaultButtonComponent = ({ children, ...rest }) => (
  <Button variant="contained" color="primary" {...rest}>
    {children}
  </Button>
);

export default function SignInButton({
  className,
  text = "Sign in",
  size = "medium",
  Component = DefaultButtonComponent,
}) {
  const dispatch = useDispatch();
  const openDialog = () => {
    analytics.logEvent("click_sign_in");
    dispatch(openSignIn());
  };

  return (
    <>
      <Component className={className} onClick={openDialog} size={size}>
        {text}
      </Component>
    </>
  );
}
