import { ofType } from "redux-observable";
import { catchError, from, mergeMap, of } from "rxjs";

import { SnackbarSeverity, showSnackbar } from "../../../lib/store/snackbar";
import { SignInModes, reauthenticate } from "../../signIn";
import { updateEmail, updateEmailDone } from "../slice";
import { openProfile, openVerifyEmailScreen } from "..";

export default (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(updateEmail),
    mergeMap((action) =>
      of(action).pipe(
        mergeMap(async (action) => {
          const user = firebase.auth().currentUser;
          const email = action.payload.email.trim();

          await user.updateEmail(email);
          await firebase.firestore().collection("users").doc(user.uid).set(
            {
              email,
            },
            { merge: true }
          );

          return [
            updateEmailDone(),
            openProfile(),
            showSnackbar(
              "Email updated successfully",
              SnackbarSeverity.SUCCESS
            ),
          ];
        }),
        mergeMap((promise) => from(promise)),
        catchError((error) => {
          if (error.code === "auth/invalid-email") {
            return [
              showSnackbar("Invalid email address", SnackbarSeverity.ERROR),
              updateEmailDone(),
            ];
          } else if (error.code === "auth/email-already-in-use") {
            return [
              showSnackbar(
                "That email address is already in use",
                SnackbarSeverity.ERROR
              ),
              updateEmailDone(),
            ];
          } else if (error.code === "auth/requires-recent-login") {
            return [
              reauthenticate(SignInModes.reauthForEmail),
              updateEmailDone(),
              openVerifyEmailScreen(),
            ];
          } else {
            console.error("Update email error:", error);
            return [
              showSnackbar(
                "Email update failed, please try again",
                SnackbarSeverity.ERROR
              ),
              updateEmailDone(),
            ];
          }
        })
      )
    )
  );
