import React from "react";

import { InputBase, Paper } from "@mui/material";

import withStyles from "@mui/styles/withStyles";

import { theme } from "../Theme";

const styles = {
  root: {
    width: theme.spacing(30),
    height: theme.spacing(6),
    boxSizing: "border-box",
    padding: theme.spacing(1, 2),
    borderRadius: 10,
    marginBottom: theme.spacing(2),
    boxShadow: "none",
    "& input": {
      width: theme.spacing(26),
      color: theme.palette.dark.main,
    },
  },
};

function Input(props) {
  const { classes, paperProps, ...inputProps } = props;
  return (
    <Paper className={classes.root} {...paperProps}>
      <InputBase {...inputProps} />
    </Paper>
  );
}

export default withStyles(styles)(Input);
