import React from "react";
import { Router as BaseRouter, Route, Switch } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";

import { ScrollToTop, Spinner } from "../atoms";
import Footer from "./Footer";
import Header from "./Header";
import SignInDialog from "./auth/SignInDialog";

/**
 * Route mappings take the form {path: config} in render priority order.
 *
 * Config parameters
 * -----------------
 * srcPath: string
 *    Import path relative to the src/ directory
 * exportName: string, optional
 *    Name of exported component; if not specified, a default export is assumed
 * priority: string, optional
 *    Sitemap priority field; valid values are from 0.1 to 1.0
 */

function lazy(factory, exportName = "default") {
  return React.lazy(() =>
    factory().then((module) => ({ default: module[exportName] }))
  );
}

export function preload(routes) {
  return Promise.all(
    Object.entries(routes).map(([path, config]) =>
      import(`../../${config.srcPath}`)
    )
  );
}

const useStyles = makeStyles({
  page: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
  },
});

export default function Router({ routes, history }) {
  const classes = useStyles();
  return (
    <BaseRouter history={history}>
      <ScrollToTop />
      <SignInDialog />
      <div className={classes.page}>
        <Header />
        <div className={classes.content}>
          <Switch>
            {Object.entries(routes).map(([path, config]) => {
              const Component = lazy(
                () => import(`../../${config.srcPath}`),
                config.exportName
              );
              return (
                <Route key={path} path={path}>
                  <React.Suspense fallback={<Spinner />}>
                    <Component />
                  </React.Suspense>
                </Route>
              );
            })}
          </Switch>
        </div>
        <Footer />
      </div>
    </BaseRouter>
  );
}
