import { useSelector } from "react-redux";

import firebase from "../../firebase";

export function useAuthState() {
  return useSelector((state) => state.auth);
}

export function useUser() {
  useAuthState(); // Add a rerender dependency on auth state changes
  return firebase.auth().currentUser;
}

export function useIsSignedIn() {
  return useUser() !== null;
}
