import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { createAction, createSlice } from "@reduxjs/toolkit";

import firebase, { analytics } from "../../firebase";
import { KEY, SignInModes, SignInScreens } from "./constants";

export const openSignIn = createAction(`maeva/${KEY}/openSignIn`);
export const closeSignIn = createAction(`maeva/${KEY}/closeSignIn`);
export const checkUserExists = createAction(
  `maeva/${KEY}/checkUserExists`,
  (email) => ({ payload: { email } })
);
export const sendSignInEmail = createAction(
  `maeva/${KEY}/sendSignInEmail`,
  (mode, email, name) => ({ payload: { mode, email, name } })
);
export const resendSignInEmail = createAction(
  `maeva/${KEY}/resendSignInEmail`,
  (mode) => ({ payload: { mode } })
);
export const reauthenticate = createAction(
  `maeva/${KEY}/reauthenticate`,
  (mode) => ({ payload: { mode } })
);
export const signInEmailSent = createAction(`maeva/${KEY}/signInEmailSent`);
export const catchLaunchSignInError = createAction(
  `maeva/${KEY}/catchLaunchSignInError`
);
export const askForName = createAction(`maeva/${KEY}/askForName`);
export const signInLaunchDone = createAction(`maeva/${KEY}/signInLaunchDone`);
export const backToRequestEmail = createAction(
  `maeva/${KEY}/backToRequestEmail`
);
export const startSignIn = createAction(`maeva/${KEY}/startSignIn`);
export const reaffirmSignInEmail = createAction(
  `maeva/${KEY}/reaffirmSignInEmail`
);
export const clearSignInEmail = createAction(`maeva/${KEY}/clearSignInEmail`);
export const validateSignIn = createAction(`maeva/${KEY}/validateSignIn`);
export const createUser = createAction(`maeva/${KEY}/createUser`);
export const catchValidateSignInError = createAction(
  `maeva/${KEY}/catchValidateSignInError`
);
export const finishSignIn = createAction(`maeva/${KEY}/finishSignIn`);

const persistConfig = {
  key: KEY,
  storage: storage,
  whitelist: ["name", "email"],
};

const slice = createSlice({
  name: KEY,
  initialState: {
    name: null,
    email: null,

    // Part 1: collect user details
    launch: {
      activeScreen: null, // The currently open sign in / up screen
      pending: false, // Is an external call pending?
      mode: null, // One of `SignInModes`
    },

    // Part 2: validate the sign-in link
    validation: {
      active: false, // Are we currently processing sign-in (not a sign in request)
      pending: false, // Is an external call pending?
      mode: null, // One of `SignInModes`
    },
  },
  extraReducers: (builder) => {
    builder.addCase(openSignIn, (state, action) => {
      state.launch.activeScreen = SignInScreens.RequestEmail;
      state.launch.mode = SignInModes.signIn;
    });

    builder.addCase(closeSignIn, (state, action) => {
      state.launch.activeScreen = null;
    });

    builder.addCase(checkUserExists, (state, action) => {
      state.launch.pending = true;
    });

    builder.addCase(sendSignInEmail, (state, action) => {
      state.launch.pending = true;
      state.launch.mode = action.payload.mode;
      state.email = action.payload.email.trim();
      if (action.payload.name) {
        state.name = action.payload.name.trim();
      }
    });

    builder.addCase(resendSignInEmail, (state, action) => {
      state.launch.pending = true;
      state.launch.mode = action.payload.mode;
    });

    builder.addCase(reauthenticate, (state, action) => {
      state.email = firebase.auth().currentUser.email;
    });

    builder.addCase(signInEmailSent, (state, action) => {
      state.launch.pending = false;
      state.launch.activeScreen = SignInScreens.Verify;
    });

    builder.addCase(askForName, (state, action) => {
      state.launch.pending = false;
      state.launch.activeScreen = SignInScreens.RequestName;
      state.launch.mode = SignInModes.signUp;
    });

    builder.addCase(signInLaunchDone, (state, action) => {
      state.launch.pending = false;
      state.launch.activeScreen = null;
      state.launch.mode = SignInModes.signIn;
    });

    builder.addCase(catchLaunchSignInError, (state, action) => {
      state.launch.pending = false;
    });

    builder.addCase(backToRequestEmail, (state, action) => {
      state.launch.activeScreen = SignInScreens.RequestEmail;
      state.launch.mode = SignInModes.signIn;
    });

    builder.addCase(startSignIn, (state) => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const fallbackMode = SignInModes.signIn;
      let mode = urlSearchParams.get("type") ?? fallbackMode;
      let name = urlSearchParams.get("name");

      if (!mode) {
        console.error("No sign-in mode provided");
      }

      state.name = name ?? state.name;
      state.validation.active = true;
      state.validation.mode = isNaN(Number(mode)) ? fallbackMode : Number(mode);
    });

    builder.addCase(reaffirmSignInEmail, (state, action) => {
      state.email = action.payload;
      state.validation.pending = true;
    });

    builder.addCase(clearSignInEmail, (state, action) => {
      state.email = null;
    });

    builder.addCase(catchValidateSignInError, (state, action) => {
      state.validation.pending = false;
    });

    builder.addCase(finishSignIn, (state, action) => {
      analytics.logEvent("login", {
        method: "EmailLink",
        uid: firebase.auth().currentUser.uid,
        mode: state.validation.mode,
        email: state.email,
        name: state.name,
      });

      // Reset everything
      state.name = null;
      state.email = null;
      state.validation.active = false;
      state.validation.pending = false;
      state.validation.mode = null;

      window.history.replaceState(
        null,
        document.title,
        window.location.pathname
      );
    });
  },
});

export const signInReducer = persistReducer(persistConfig, slice.reducer);
