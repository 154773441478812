import React from "react";
import { useDispatch } from "react-redux";

import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import {
  Checkbox,
  Divider,
  Input,
  ListItemText,
  MenuItem,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { theme } from "../Theme";
import { showSnackbar } from "../store/snackbar";
import Select from "./Select";

/**
 *
 * @param {string} rem measurement in rem, e.g. "1.5rem"
 * @returns A number of pixels, e.g. 24
 */
function convertRemToPixels(rem) {
  return (
    parseFloat(rem) *
    parseFloat(getComputedStyle(document.documentElement).fontSize)
  );
}

const SEARCH_FILTER_VALUE = "__search_filter__";
const SELECT_ALL_VALUE = "__select_all__";
const DONE_BUTTON_VALUE = "__done_btn__";
const MENU_WRAPPER_VALUE = "__menu_wrapper__";
const VISIBLE_MENU_ITEMS = 7; // The height of the scrollable area of the select menu by number of items visible
const CONTROL_MENU_ITEMS = 2.8; // Used to calculate MENU_HEIGHT - number of full height controls (e.g. search, select all, done)
const BUFFER_MENU_ITEMS = 2; // Number of menu items to render above and below the visible menu items
const MAX_RENDERED_MENU_ITEMS = VISIBLE_MENU_ITEMS + BUFFER_MENU_ITEMS; // The maximum number of menu items to render
const MENU_ITEM_SPACING = 5; // The height of a menu item in units for `theme.spacing`
const MENU_ITEM_HEIGHT = theme.spacing(MENU_ITEM_SPACING);
const SEARCH_FILTER_HEIGHT = theme.spacing(MENU_ITEM_SPACING * 0.8);
const SCROLLABLE_MENU_HEIGHT = theme.spacing(
  MENU_ITEM_SPACING * VISIBLE_MENU_ITEMS
);
const MENU_HEIGHT = theme.spacing(
  MENU_ITEM_SPACING * (VISIBLE_MENU_ITEMS + CONTROL_MENU_ITEMS)
);

const useStyles = makeStyles((theme) => ({
  menu: {
    "& .MuiPaper-root": {
      overflowY: "hidden",
    },
    "& .MuiList-root": {
      paddingTop: 0,
      paddingBottom: 0,
      maxHeight: MENU_HEIGHT,
    },
  },
  scrollableMenu: {
    overflowY: "auto",
    maxHeight: SCROLLABLE_MENU_HEIGHT,
    "&>div": {
      position: "relative",
    },
  },
  titleMenuItem: {
    background: theme.palette.light.main,
    height: MENU_ITEM_HEIGHT,
    "& .MuiListItemText-root": {
      marginLeft: theme.spacing(-3),
      textAlign: "center",
    },
  },
  menuItem: {
    position: "absolute",
    width: "100%",
    height: MENU_ITEM_HEIGHT,
  },
  searchInput: {
    height: SEARCH_FILTER_HEIGHT,
    "& input": {
      padding: 0,
    },
  },
  doneBtn: {
    background: theme.palette.light.main,
    textAlign: "center",
    height: MENU_ITEM_HEIGHT,
  },
}));

/**
 * Virtualised select component that allows the user to select multiple options from a list of choices.
 * @param {string[]} choices the list of choices to select from
 * @param {string[]} defaultChecked the list of choices to check when the dropdown is opened
 * @param {boolean} checkAllByDefault whether to check all choices by default
 * @param {function} onChange callback function to be called when the menu is closed
 * @param {boolean} cannotBeEmpty whether the user must select at least one option
 * @param {*} props passed to `Select`
 */
export default function CheckboxDropdown({
  choices,
  defaultChecked = [],
  checkAllByDefault = false,
  onChange,
  cannotBeEmpty = false,
  ...props
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [allChecked, setAllChecked] = React.useState(
    checkAllByDefault && defaultChecked.length === choices.length
  );
  const [checked, setChecked] = React.useState(
    allChecked ? [...choices] : [...defaultChecked]
  );
  const [windowStart, setWindowStart] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const menuItemHeight = convertRemToPixels(MENU_ITEM_HEIGHT);
  const filteredChoices = choices.filter((choice) =>
    choice.toLowerCase().includes(search)
  );
  const nRenderedMenuItems = Math.min(
    filteredChoices.length,
    MAX_RENDERED_MENU_ITEMS
  );

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    if (choices.length > 0 && checked.length === 0 && cannotBeEmpty) {
      dispatch(showSnackbar("Please select at least one option"));
    } else {
      setOpen(false);
      setSearch(""); // Clear the search state before the dropdown is reopened
      onChange && onChange(checked);
    }
  };

  const handleChange = (event) => {
    const newChecked = event.target.value;

    // Handle clicks on items with no values (e.g. the Divider)
    if (newChecked.includes(undefined)) {
      return;
    }

    // Handle search filter
    if (newChecked.includes(SEARCH_FILTER_VALUE)) {
      return;
    }

    // Handle done button
    if (newChecked.includes(DONE_BUTTON_VALUE)) {
      return;
    }

    // Handle "all" choice
    if (newChecked.includes(SELECT_ALL_VALUE)) {
      if (allChecked) {
        // A choice other than "all" was clicked, so uncheck "all"
        setAllChecked(false);
        setChecked([]);
        return;
      } else {
        // All was not previously selected, so check all choices
        setAllChecked(true);
        setChecked([...choices]);
        return;
      }
    }

    // Handle items in the menu wrapper
    const menuWrapperIndex = newChecked.indexOf(MENU_WRAPPER_VALUE);

    if (menuWrapperIndex > -1) {
      newChecked.splice(menuWrapperIndex, 1);

      const choice = event.nativeEvent.target.innerText;
      const index = newChecked.indexOf(choice);

      if (index > -1) {
        newChecked.splice(index, 1);
      } else {
        newChecked.push(choice);
      }
    }

    // Handle all other choices
    if (newChecked.length === choices.length) {
      setAllChecked(true);
    } else if (allChecked) {
      setAllChecked(false);
    }

    setChecked(newChecked);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value.toLowerCase());
    handleScroll(event);
  };

  // Prevent MUI's default "select by typing" behaviour interfering with the
  // search filter. When we don't do this, pressing "d" will select "done" or
  // "deselect all" if those options are present in the dropdown.
  const cancelSelectByTyping = (event) => event.stopPropagation();

  const handleScroll = (event) => {
    const offset = Math.floor(event.target.scrollTop / menuItemHeight);
    setWindowStart(
      Math.min(offset, filteredChoices.length - nRenderedMenuItems)
    );
  };

  const renderMenu = () => {
    const items = [];

    for (let i = 0; i < nRenderedMenuItems; i++) {
      const index = windowStart + i;
      const choice = filteredChoices[index];
      const style = { top: index * menuItemHeight };

      items.push(
        <MenuItem
          key={i}
          className={classes.menuItem}
          value={choice}
          dense
          style={style}
          onClick={() => {
            if (checked.includes(choice)) {
              setChecked(checked.filter((item) => item !== choice));
              setAllChecked(false);
            } else {
              setAllChecked(checked.length === choices.length - 1);
              setChecked([...checked, choice]);
            }
          }}
        >
          <Checkbox checked={checked.indexOf(choice) > -1} color="primary" />
          <ListItemText primary={choice} />
        </MenuItem>
      );
    }

    return items;
  };

  const renderValue = (selected) => selected.join(", ");

  React.useEffect(() => {
    setChecked([...defaultChecked]);
    setAllChecked(defaultChecked.length === choices.length);
  }, [choices, defaultChecked]);

  return (
    <Select
      multiple
      inline
      open={open}
      value={checked}
      renderValue={renderValue}
      onChange={handleChange}
      onOpen={handleOpen}
      onClose={handleClose}
      menuClassName={classes.menu}
      {...props}
    >
      <MenuItem
        className={classes.titleMenuItem}
        dense
        divider
        value={SELECT_ALL_VALUE}
      >
        <Checkbox
          checked={allChecked}
          indeterminate={checked.length > 0 && !allChecked}
          indeterminateIcon={<IndeterminateCheckBoxIcon color="secondary" />}
          color="primary"
        />
        <ListItemText primary={allChecked ? "Deselect all" : "Select all"} />
      </MenuItem>
      <MenuItem
        className={classes.searchInput}
        dense
        divider
        value={SEARCH_FILTER_VALUE}
      >
        <Input
          onChange={handleSearch}
          onKeyDown={cancelSelectByTyping}
          placeholder="Filter"
          size="small"
          margin="none"
          fullWidth
          disableUnderline
        />
      </MenuItem>
      <div
        className={classes.scrollableMenu}
        onScroll={handleScroll}
        value={MENU_WRAPPER_VALUE}
      >
        <div style={{ height: menuItemHeight * filteredChoices.length }}>
          {renderMenu()}
        </div>
      </div>
      <Divider />
      <MenuItem
        className={classes.doneBtn}
        dense
        disabled={checked.length === 0}
        onClick={handleClose}
        value={DONE_BUTTON_VALUE}
      >
        <ListItemText primary="Done" />
      </MenuItem>
    </Select>
  );
}
