import classNames from "classnames";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Chip, Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { analytics } from "../../firebase";
import {
  DEFAULT_SPF_TYPE,
  MAX_PRICE,
  MAX_SPF,
  MIN_PRICE,
  MIN_SPF,
  search,
  useSearchState,
} from "../../store/search";
import { theme } from "../Theme";
import { Button } from "../atoms";
import {
  BrandSelector,
  ExcludedIngredientsSelector,
  PriceSlider,
  SpfSlider,
  SpfTypeSelector,
  VendorSelector,
} from ".";

const useStyles = makeStyles({
  root: {
    maxWidth: 375,
    margin: "0 auto",
    background: theme.palette.white.main,
    padding: theme.spacing(6),
    boxSizing: "border-box",
    "& .MuiTypography-subtitle1": {
      fontWeight: "bold",
    },
  },
  searchButton: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  comingSoonSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing(2)} 0`,
  },
  comingSoonLabel: {
    color: theme.palette.white.main,
  },
});

function ComingSoonSection({ name }) {
  const classes = useStyles();
  return (
    <div className={classes.comingSoonSection}>
      <Typography variant="subtitle1" color="secondary">
        {name}
      </Typography>
      <Chip
        classes={{ label: classes.comingSoonLabel }}
        label="coming soon"
        color="primary"
        size="small"
      />
    </div>
  );
}

export default function AdvancedSearch({
  className,
  useCachedState = false,
  hideSearchButton = false,
  searchOnChange = false,
  onSearch = null,
}) {
  const classes = useStyles();
  const searchState = useSearchState();
  const dispatch = useDispatch();
  const firstRender = React.useRef(true);

  const [spfType, setSpfType] = useState(
    useCachedState ? searchState.spfType ?? DEFAULT_SPF_TYPE : DEFAULT_SPF_TYPE
  );
  const [spfRange, setSpfRange] = useState(
    useCachedState ? searchState.spfRange : [MIN_SPF, MAX_SPF]
  );
  const [priceRange, setPriceRange] = useState(
    useCachedState ? searchState.priceRange : [MIN_PRICE, MAX_PRICE]
  );
  const [excludes, setExcludes] = useState(
    useCachedState ? searchState.excludes : []
  );
  const [vendorIds, setVendorIds] = useState(
    useCachedState ? searchState.vendorIds : []
  );
  const [brandIds, setBrandIds] = useState(
    useCachedState ? searchState.brandIds : []
  );

  const doSearch = React.useCallback(() => {
    onSearch && onSearch();
    dispatch(
      search({ spfType, spfRange, priceRange, excludes, vendorIds, brandIds })
    );
  }, [
    onSearch,
    dispatch,
    spfType,
    spfRange,
    priceRange,
    excludes,
    vendorIds,
    brandIds,
  ]);

  const handleSearch = () => {
    if (document.location.pathname.startsWith("/search")) {
      analytics.logEvent("click_search_in_drawer");
    } else {
      analytics.logEvent("click_search_on_homepage");
    }

    doSearch();
  };

  React.useEffect(() => {
    setSpfType(searchState.spfType ?? DEFAULT_SPF_TYPE);
  }, [searchState.spfType]);

  React.useEffect(() => {
    setSpfRange(searchState.spfRange);
  }, [searchState.spfRange]);

  React.useEffect(() => {
    setPriceRange(searchState.priceRange);
  }, [searchState.priceRange]);

  React.useEffect(() => {
    setExcludes(searchState.excludes);
  }, [searchState.excludes]);

  React.useEffect(() => {
    setVendorIds(searchState.vendorIds);
  }, [searchState.vendorIds]);

  React.useEffect(() => {
    setBrandIds(searchState.brandIds);
  }, [searchState.brandIds]);

  React.useEffect(() => {
    // Don't search on first render
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if (searchOnChange) {
      analytics.logEvent("search_on_change");
      doSearch();
    }
  }, [firstRender, doSearch, searchOnChange]);

  return (
    <div className={classNames(classes.root, className)}>
      <SpfTypeSelector value={spfType} onChange={setSpfType} />
      <SpfSlider value={spfRange} onChange={setSpfRange} />
      <PriceSlider value={priceRange} onChange={setPriceRange} />
      <ExcludedIngredientsSelector value={excludes} onChange={setExcludes} />
      <VendorSelector value={vendorIds} onChange={setVendorIds} />
      <BrandSelector value={brandIds} onChange={setBrandIds} />
      <ComingSoonSection name="Vegan" />
      <Divider />
      <ComingSoonSection name="Cruelty free" />
      <Divider />
      <ComingSoonSection name="White cast" />
      <Divider />
      <ComingSoonSection name="Matte or dewy" />
      <Divider />
      <ComingSoonSection name="Asian beauty brands" />
      {!hideSearchButton && (
        <Button
          className={classes.searchButton}
          variant="contained"
          size="large"
          color="primary"
          onClick={handleSearch}
        >
          Search
        </Button>
      )}
    </div>
  );
}
