import { SPFType } from "@maeva/api/search";

export const KEY = "search";

export const DEFAULT_SPF_TYPE = SPFType.All;
export const MIN_SPF = 20;
export const MAX_SPF = 50;
export const MIN_PRICE = 0;
export const MAX_PRICE = 250;

export const INITIAL_SORT = "popularity_desc";
export const INITIAL_SEARCH_STATE = {
  spfType: DEFAULT_SPF_TYPE,
  spfRange: [MIN_SPF, MAX_SPF],
  priceRange: [MIN_PRICE, MAX_PRICE],
  excludes: [],
  vendorIds: [],
  brandIds: [],
  sort: INITIAL_SORT,
};
