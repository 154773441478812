import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import firebase from "firebase/app";

import firebaseConfig from "./config/firebaseConfig.json";

firebase.initializeApp(firebaseConfig);

const USE_ANALYTICS_IN_DEV = false;
export const analytics = firebase.analytics();

if (process.env.NODE_ENV === "development") {
  // Hosts and ports for each emulator are defined in firebase.json
  firebase
    .auth()
    .useEmulator("http://localhost:5003", { disableWarnings: true });
  firebase.firestore().useEmulator("localhost", 5002);
  firebase.functions().useEmulator("localhost", 5000);
  analytics.setAnalyticsCollectionEnabled(USE_ANALYTICS_IN_DEV);
}

export default firebase;
