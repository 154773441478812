import { combineEpics } from "redux-observable";
import { catchError } from "rxjs";

import { maevaUIRootEpic } from "../lib/store";
import { authEpics } from "./auth";
import { profileEpics } from "./profile";
import { searchEpics } from "./search";
import { signInEpics } from "./signIn";

const epics = [
  ...maevaUIRootEpic,
  ...authEpics,
  ...profileEpics,
  ...searchEpics,
  ...signInEpics,
];

export default (action$, store$, dependencies) =>
  combineEpics(...epics)(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      console.error(error);
      return source;
    })
  );
