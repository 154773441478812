import React from "react";
import { QueryClientProvider } from "react-query";

import { CssBaseline } from "@mui/material";

import ROUTES from "./config/routes.json";
import MeavaThemeProvider from "./lib/Theme";
import { Feedback, GlobalSnackbar } from "./lib/atoms";
import { Router } from "./lib/molecules";
import { SplashScreen, ValidateSignInDialog } from "./lib/molecules/auth";
import { GlobalErrorBoundary } from "./pages/Error/GlobalErrorBoundary";
import { StoreProvider } from "./store";
import { history } from "./utils/history";
import { queryClient } from "./utils/queryClient";

export default function App() {
  return (
    <MeavaThemeProvider>
      <CssBaseline />
      <GlobalErrorBoundary>
        <StoreProvider>
          <QueryClientProvider client={queryClient}>
            <SplashScreen>
              <ValidateSignInDialog />
              <GlobalSnackbar />
              <Feedback />
              <Router routes={ROUTES} history={history} />
            </SplashScreen>
          </QueryClientProvider>
        </StoreProvider>
      </GlobalErrorBoundary>
    </MeavaThemeProvider>
  );
}
