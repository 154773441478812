import { createAction, createSlice } from "@reduxjs/toolkit";

import firebase, { analytics } from "../../firebase";
import { KEY } from "./constants";

export const signedIn = createAction(`maeva/${KEY}/signedIn`);
export const signedOut = createAction(`maeva/${KEY}/signedOut`);
export const signOut = createAction(`maeva/${KEY}/signOut`);
export const catchSignOutError = createAction(`maeva/${KEY}/catchSignOutError`);

const slice = createSlice({
  name: KEY,
  initialState: {
    hasLoaded: false,
    signedIn: false,
    signOutPending: false,
  },
  extraReducers: (builder) => {
    builder.addCase(signedIn, (state, action) => {
      state.hasLoaded = true;
      state.signedIn = true;
    });

    builder.addCase(signedOut, (state, action) => {
      state.hasLoaded = true;
      state.signedIn = false;
      state.signOutPending = false;
    });

    builder.addCase(signOut, (state, action) => {
      const user = firebase.auth().currentUser;
      analytics.logEvent("sign_out", {
        uid: user.uid,
        email: user.email,
        name: user.displayName,
      });

      state.signOutPending = true;
    });

    builder.addCase(catchSignOutError, (state, action) => {
      state.signOutPending = false;
    });
  },
});

export const authReducer = slice.reducer;
