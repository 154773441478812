import React from "react";

import {
  Avatar,
  Chip,
  ClickAwayListener,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { theme } from "../Theme";
import { Link } from "../atoms";
import { UKFlagIcon } from "../icons";

const useStyles = makeStyles({
  popper: {
    zIndex: 1000,
  },
  paper: {
    width: 170,
    padding: theme.spacing(1),
  },
});

export default function RegionMenu({ className, size = "medium" }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const chipRef = React.useRef(null);
  const label = size === "medium" ? "United Kingdom" : "UK";

  const handleClick = () => {
    setAnchorEl(anchorEl ? null : chipRef.current);
  };
  const close = () => setAnchorEl(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <Chip
        className={className}
        ref={chipRef}
        avatar={
          <Avatar alt="UK Flag">
            <UKFlagIcon fontSize={size} />
          </Avatar>
        }
        size={size}
        label={label}
        deleteIcon={<ArrowDropDownIcon />}
        onDelete={handleClick}
        onClick={handleClick}
      />
      <Popper className={classes.popper} open={open} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={close}>
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="caption">
              We are running our beta in the UK. Want Maeva in your country
              next?{" "}
              <Link
                href="https://docs.google.com/forms/d/1cZSSu5Qm0IHIDy0wIfXUA4sJ38HOJHEn5vgr_QiXbU4"
                target="_blank"
              >
                Let us know!
              </Link>
            </Typography>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
}
