import React from "react";
import { useQuery } from "react-query";

import { fetchBrands } from "@maeva/api/brand";
import { Typography } from "@mui/material";
import { Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { CheckboxDropdown } from "../atoms";

const useStyles = makeStyles({
  root: {
    width: "100%",
    paddingLeft: 0,
    paddingRight: 0,
  },
});

/**
 * @param {string[]} value brand ids to check by default
 * @param {(state) => {}} onChange called on change with an array of brand names
 *   ["brand1", "brand2"] that are checked or [] if all/none are checked.
 * @param {*} props passed to `CheckboxDropdown`
 */
export default function BrandSelector({ value, onChange, ...props }) {
  const classes = useStyles();
  const { isLoading, isError, data, error } = useQuery(["brands"], fetchBrands);
  let content = null;

  if (isLoading) {
    content = (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={30}
        animation="wave"
      />
    );
  } else if (isError || (data && data.length === 0)) {
    console.error("Failed to load brand data, error:", error);
    content = (
      <Typography variant="body1">
        Brand filter unavailable, try reloading
      </Typography>
    );
  } else if (data) {
    // Sort alphanumerically, ignoring case
    const choices = data
      .map((brand) => brand.name)
      .sort((a, b) => a.localeCompare(b));

    // Get the currently selected names from their corresponding ids
    const getBrandNameById = (id) => data.find((b) => b.id === id).name;
    const defaultCheckedNames = value.length
      ? value.map(getBrandNameById)
      : choices;

    const handleChange = (checked) => {
      if (!onChange) {
        return;
      } else if (checked.length === choices.length) {
        // If all are checked, there's nothing to filter by
        onChange([]);
        return;
      }

      const getBrandIdByName = (name) => data.find((b) => b.name === name).id;

      onChange(checked.map(getBrandIdByName));
    };

    content = (
      <CheckboxDropdown
        className={classes.root}
        choices={choices}
        checkAllByDefault
        defaultChecked={defaultCheckedNames}
        cannotBeEmpty
        onChange={handleChange}
        aria-labelledby="brands-label"
        {...props}
      />
    );
  }

  return (
    <>
      <Typography id="brands-label" variant="subtitle1" color="secondary">
        From these brands
      </Typography>
      {content}
    </>
  );
}
