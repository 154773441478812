import React from "react";

import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { MAX_SPF, MIN_SPF } from "../../store/search";
import { Slider } from "../atoms";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTypography-subtitle1": {
      marginBottom: theme.spacing(-1),
    },
  },
  slider: {
    paddingTop: 0,
  },
}));

export default function SpfSlider({ value = [MIN_SPF, MAX_SPF], onChange }) {
  const classes = useStyles();
  const [range, setRange] = React.useState([...value]);
  const handleChange = (_, newValue) => setRange(newValue);
  const handleChangeCommitted = (_, newValue) => onChange(newValue);
  const getAriaLabel = (value) => `SPF ${value}`;
  const marks = [
    {
      value: 20,
      label: "20",
    },
    {
      value: 30,
      label: "30",
    },
    {
      value: 40,
      label: "40",
    },
    {
      value: 50,
      label: "50+",
    },
  ];

  React.useEffect(() => {
    setRange([...value]);
  }, [value]);

  return (
    <div className={classes.root}>
      <Typography id="spf-slider" variant="subtitle1" color="secondary">
        SPF level
      </Typography>
      <Slider
        className={classes.slider}
        value={range}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        min={MIN_SPF}
        max={MAX_SPF}
        step={10}
        color="secondary"
        aria-labelledby="spf-slider"
        getAriaValueText={getAriaLabel}
        marks={marks}
      />
    </div>
  );
}
