import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { selectSearchState } from ".";

export function useSearchState() {
  const { state: locationState } = useLocation();
  const storeState = useSelector(selectSearchState);
  return locationState ?? storeState;
}
