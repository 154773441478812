import { ofType } from "redux-observable";
import { catchError, from, mergeMap, of, withLatestFrom } from "rxjs";

import { SnackbarSeverity, showSnackbar } from "../../../lib/store/snackbar";
import { openUpdateNameScreen } from "../../profile";
import { createUser, finishSignIn } from "../slice";

export default (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(createUser),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      of(action).pipe(
        mergeMap(async () => {
          const name = state.signIn.name;

          if (name) {
            const user = firebase.auth().currentUser;
            const timestamp = new Date().getTime();

            await user.updateProfile({ displayName: name });
            await firebase.firestore().collection("users").doc(user.uid).set({
              email: user.email,
              name,
              createdAt: timestamp,
              lastLogin: timestamp,
            });

            return [finishSignIn()];
          }

          return [
            finishSignIn(),
            openUpdateNameScreen(),
            showSnackbar(
              "Finally, please set your profile name",
              SnackbarSeverity.INFO
            ),
          ];
        }),
        mergeMap((promise) => from(promise)),
        catchError((error) => {
          console.error(`Error ${error.code}:`, error.message);
          return [
            finishSignIn(),
            openUpdateNameScreen(),
            showSnackbar(
              "Finally, please set your profile name",
              SnackbarSeverity.INFO
            ),
          ];
        })
      )
    )
  );
