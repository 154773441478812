const TITLE_TEMPLATE = process.env.REACT_APP_META_PAGE_TITLE;

export const getMetaSetter = (document) => {
  const getMetaByAttr = (attr, value) => {
    const elements = document.head.getElementsByTagName("meta");

    for (const element of elements) {
      if (element.getAttribute(attr) === value) {
        return element;
      }
    }

    return null;
  };

  const getMetaByName = (value) => getMetaByAttr("name", value);
  const getMetaByProperty = (value) => getMetaByAttr("property", value);

  const setContent = (element, content) => {
    if (element) {
      element.content = content;
    }
  };

  const setPageMeta = ({
    url,
    title,
    description,
    keywords,
    metaImageUrl,
    metaImageAlt,
  }) => {
    title = title
      ? TITLE_TEMPLATE.replace("{{title}}", title.trim())
      : process.env.REACT_APP_META_TITLE;
    keywords = keywords ? keywords.trim() : process.env.REACT_APP_META_KEYWORDS;
    description = description
      ? description.trim()
      : process.env.REACT_APP_META_DESCRIPTION;
    metaImageUrl =
      metaImageUrl ||
      process.env.REACT_APP_META_URL + process.env.REACT_APP_META_IMAGE_PATH;
    metaImageAlt = metaImageAlt
      ? metaImageAlt.trim()
      : process.env.REACT_APP_META_IMAGE_ALT;

    // Primary Meta Tags
    document.title = title;
    setContent(getMetaByName("title"), title);
    setContent(getMetaByName("description"), description);
    setContent(getMetaByName("keywords"), keywords);

    // Open Graph / Facebook Meta Tags
    setContent(getMetaByProperty("og:url"), url, "property");
    setContent(getMetaByProperty("og:title"), title, "property");
    setContent(getMetaByProperty("og:description"), description, "property");
    setContent(getMetaByProperty("og:image"), metaImageUrl, "property");
    setContent(getMetaByProperty("og:image:alt"), metaImageAlt, "property");

    // Twitter Meta Tags
    setContent(getMetaByName("twitter:url"), url);
    setContent(getMetaByName("twitter:title"), title);
    setContent(getMetaByName("twitter:description"), description);
    setContent(getMetaByName("twitter:image"), metaImageUrl);
    setContent(getMetaByName("twitter:image:alt"), metaImageAlt);
  };

  return setPageMeta;
};
