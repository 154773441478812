import { createAction, createSlice } from "@reduxjs/toolkit";

import { SNACKBAR_AUTOHIDE_DURATION } from "../../config";
import { KEY, SNACKBAR_DEFAULT_SEVERITY } from "./constants";

export const showSnackbar = createAction(
  `maeva/${KEY}/showSnackbar`,
  (message, severity, duration) => ({
    payload: {
      shown: true,
      message,
      severity: severity ?? SNACKBAR_DEFAULT_SEVERITY,
      duration: duration ?? SNACKBAR_AUTOHIDE_DURATION,
    },
  })
);
export const hideSnackbar = createAction(`maeva/${KEY}/hideSnackbar`);

const slice = createSlice({
  name: KEY,
  initialState: {
    shown: false,
    message: null,
    severity: SNACKBAR_DEFAULT_SEVERITY,
    duration: SNACKBAR_AUTOHIDE_DURATION,
  },
  extraReducers: (builder) => {
    builder.addCase(showSnackbar, (state, action) => {
      state.shown = action.payload.shown;
      state.message = action.payload.message;
      state.severity = action.payload.severity;
      state.duration = action.payload.duration;
    });

    builder.addCase(hideSnackbar, (state, action) => {
      state.shown = false;
    });
  },
});

export const snackbarReducer = slice.reducer;
