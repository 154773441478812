import "swiper/swiper-bundle.min.css";

import classNames from "classnames";
import React from "react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import makeStyles from "@mui/styles/makeStyles";

import { theme } from "../Theme";

SwiperCore.use([Navigation, Pagination]);

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  swiper: {
    "&.swiper-container": {
      marginBottom: theme.spacing(-2),
      paddingBottom: theme.spacing(4),
    },
    "& .swiper-pagination-bullet.swiper-pagination-bullet-active": {
      background: theme.palette.dark.main,
    },
    "& .swiper-button-prev, .swiper-button-next": {
      color: theme.palette.primary.main,
      transform: "translateY(-50%)",
      "&.swiper-button-disabled": {
        opacity: 0,
      },
    },
    "& .swiper-button-prev": {
      left: 0,
    },
    "& .swiper-button-next": {
      right: 0,
    },
  },
});

export default function Carousel({
  navigation = false,
  className,
  children,
  ...props
}) {
  const classes = useStyles();
  return (
    <div className={classNames(classes.root, className)}>
      {React.Children.count(children) >= 2 ? (
        <Swiper
          className={classes.swiper}
          navigation={navigation}
          pagination={{ clickable: true }}
          {...props}
        >
          {React.Children.map(children, (Child) => (
            <SwiperSlide>{Child}</SwiperSlide>
          ))}
        </Swiper>
      ) : (
        children
      )}
    </div>
  );
}
